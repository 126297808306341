const CitiesData = [
    {
        "State": "Arizona",
        "City": "Phoenix",
        "StateID": "AZ",
        "url": "phoenix-az"
    },
    {
        "State": "Arizona",
        "City": "Tucson",
        "StateID": "AZ",
        "url": "tucson-az"
    },
    {
        "State": "Arizona",
        "City": "Mesa",
        "StateID": "AZ",
        "url": "mesa-az"
    },
    {
        "State": "Arizona",
        "City": "Chandler",
        "StateID": "AZ",
        "url": "chandler-az"
    },
    {
        "State": "Arizona",
        "City": "Scottsdale",
        "StateID": "AZ",
        "url": "scottsdale-az"
    },
    {
        "State": "Arizona",
        "City": "Glendale",
        "StateID": "AZ",
        "url": "glendale-az"
    },
    {
        "State": "Arizona",
        "City": "Gilbert",
        "StateID": "AZ",
        "url": "gilbert-az"
    },
    {
        "State": "Arizona",
        "City": "Tempe",
        "StateID": "AZ",
        "url": "tempe-az"
    },
    {
        "State": "Arizona",
        "City": "Peoria",
        "StateID": "AZ",
        "url": "peoria-az"
    },
    {
        "State": "Arizona",
        "City": "Surprise",
        "StateID": "AZ",
        "url": "surprise-az"
    },
    {
        "State": "Arizona",
        "City": "Yuma",
        "StateID": "AZ",
        "url": "yuma-az"
    },
    {
        "State": "Arizona",
        "City": "Avondale",
        "StateID": "AZ",
        "url": "avondale-az"
    },
    {
        "State": "Arizona",
        "City": "Goodyear",
        "StateID": "AZ",
        "url": "goodyear-az"
    },
    {
        "State": "Arizona",
        "City": "Flagstaff",
        "StateID": "AZ",
        "url": "flagstaff-az"
    },
    {
        "State": "Arizona",
        "City": "Buckeye",
        "StateID": "AZ",
        "url": "buckeye-az"
    },
    {
        "State": "Arizona",
        "City": "Lake Havasu City",
        "StateID": "AZ",
        "url": "lake-havasu-city-az"
    },
    {
        "State": "Arizona",
        "City": "Casa Grande",
        "StateID": "AZ",
        "url": "casa-grande-az"
    },
    {
        "State": "Arizona",
        "City": "Sierra Vista",
        "StateID": "AZ",
        "url": "sierra-vista-az"
    },
    {
        "State": "Arizona",
        "City": "Maricopa",
        "StateID": "AZ",
        "url": "maricopa-az"
    },
    {
        "State": "Arizona",
        "City": "Oro Valley",
        "StateID": "AZ",
        "url": "oro-valley-az"
    },
    {
        "State": "Arizona",
        "City": "Prescott",
        "StateID": "AZ",
        "url": "prescott-az"
    },
    {
        "State": "Arizona",
        "City": "Bullhead City",
        "StateID": "AZ",
        "url": "bullhead-city-az"
    },
    {
        "State": "Arizona",
        "City": "Prescott Valley",
        "StateID": "AZ",
        "url": "prescott-valley-az"
    },
    {
        "State": "Arizona",
        "City": "Marana",
        "StateID": "AZ",
        "url": "marana-az"
    },
    {
        "State": "Arizona",
        "City": "Apache Junction",
        "StateID": "AZ",
        "url": "apache-junction-az"
    },
    {
        "State": "Arizona",
        "City": "Queen Creek",
        "StateID": "AZ",
        "url": "queen-creek-az"
    },
    {
        "State": "Arizona",
        "City": "El Mirage",
        "StateID": "AZ",
        "url": "el-mirage-az"
    },
    {
        "State": "Arizona",
        "City": "Kingman",
        "StateID": "AZ",
        "url": "kingman-az"
    },
    {
        "State": "Arizona",
        "City": "San Luis",
        "StateID": "AZ",
        "url": "san-luis-az"
    },
    {
        "State": "Arizona",
        "City": "Sahuarita",
        "StateID": "AZ",
        "url": "sahuarita-az"
    },
    {
        "State": "Arizona",
        "City": "Fountain Hills",
        "StateID": "AZ",
        "url": "fountain-hills-az"
    },
    {
        "State": "Arizona",
        "City": "Douglas",
        "StateID": "AZ",
        "url": "douglas-az"
    },
    {
        "State": "Arizona",
        "City": "Sedona",
        "StateID": "AZ",
        "url": "sedona-az"
    },
    {
        "State": "Arizona",
        "City": "Cottonwood",
        "StateID": "AZ",
        "url": "cottonwood-az"
    },
    {
        "State": "Arizona",
        "City": "Payson",
        "StateID": "AZ",
        "url": "payson-az"
    },
    {
        "State": "Arizona",
        "City": "Paradise Valley",
        "StateID": "AZ",
        "url": "paradise-valley-az"
    },
    {
        "State": "Arizona",
        "City": "Eloy",
        "StateID": "AZ",
        "url": "eloy-az"
    },
    {
        "State": "Arizona",
        "City": "Florence",
        "StateID": "AZ",
        "url": "florence-az"
    },
    {
        "State": "Arizona",
        "City": "Winslow",
        "StateID": "AZ",
        "url": "winslow-az"
    },
    {
        "State": "Arizona",
        "City": "Show Low",
        "StateID": "AZ",
        "url": "show-low-az"
    },
    {
        "State": "Arizona",
        "City": "Page",
        "StateID": "AZ",
        "url": "page-az"
    },
    {
        "State": "Arizona",
        "City": "Coolidge",
        "StateID": "AZ",
        "url": "coolidge-az"
    },
    {
        "State": "Arizona",
        "City": "Globe",
        "StateID": "AZ",
        "url": "globe-az"
    },
    {
        "State": "Arizona",
        "City": "Camp Verde",
        "StateID": "AZ",
        "url": "camp-verde-az"
    },
    {
        "State": "Arizona",
        "City": "Bisbee",
        "StateID": "AZ",
        "url": "bisbee-az"
    },
    {
        "State": "Georgia",
        "City": "Atlanta",
        "StateID": "GA",
        "url": "atlanta-ga"
    },
    {
        "State": "Georgia",
        "City": "Augusta",
        "StateID": "GA",
        "url": "augusta-ga"
    },
    {
        "State": "Georgia",
        "City": "Columbus",
        "StateID": "GA",
        "url": "columbus-ga"
    },
    {
        "State": "Georgia",
        "City": "Macon",
        "StateID": "GA",
        "url": "macon-ga"
    },
    {
        "State": "Georgia",
        "City": "Savannah",
        "StateID": "GA",
        "url": "savannah-ga"
    },
    {
        "State": "Georgia",
        "City": "Athens",
        "StateID": "GA",
        "url": "athens-ga"
    },
    {
        "State": "Georgia",
        "City": "Sandy Springs",
        "StateID": "GA",
        "url": "sandy-springs-ga"
    },
    {
        "State": "Georgia",
        "City": "Roswell",
        "StateID": "GA",
        "url": "roswell-ga"
    },
    {
        "State": "Georgia",
        "City": "Johns Creek",
        "StateID": "GA",
        "url": "johns-creek-ga"
    },
    {
        "State": "Georgia",
        "City": "Warner Robins",
        "StateID": "GA",
        "url": "warner-robins-ga"
    },
    {
        "State": "Georgia",
        "City": "Albany",
        "StateID": "GA",
        "url": "albany-ga"
    },
    {
        "State": "Georgia",
        "City": "Alpharetta",
        "StateID": "GA",
        "url": "alpharetta-ga"
    },
    {
        "State": "Georgia",
        "City": "Marietta",
        "StateID": "GA",
        "url": "marietta-ga"
    },
    {
        "State": "Georgia",
        "City": "Valdosta",
        "StateID": "GA",
        "url": "valdosta-ga"
    },
    {
        "State": "Georgia",
        "City": "Smyrna",
        "StateID": "GA",
        "url": "smyrna-ga"
    },
    {
        "State": "Georgia",
        "City": "Dunwoody",
        "StateID": "GA",
        "url": "dunwoody-ga"
    },
    {
        "State": "Georgia",
        "City": "Rome",
        "StateID": "GA",
        "url": "rome-ga"
    },
    {
        "State": "Georgia",
        "City": "East Point",
        "StateID": "GA",
        "url": "east-point-ga"
    },
    {
        "State": "Georgia",
        "City": "Milton",
        "StateID": "GA",
        "url": "milton-ga"
    },
    {
        "State": "Georgia",
        "City": "Gainesville",
        "StateID": "GA",
        "url": "gainesville-ga"
    },
    {
        "State": "Georgia",
        "City": "Peachtree City",
        "StateID": "GA",
        "url": "peachtree-city-ga"
    },
    {
        "State": "Georgia",
        "City": "Newnan",
        "StateID": "GA",
        "url": "newnan-ga"
    },
    {
        "State": "Georgia",
        "City": "Hinesville",
        "StateID": "GA",
        "url": "hinesville-ga"
    },
    {
        "State": "Georgia",
        "City": "Dalton",
        "StateID": "GA",
        "url": "dalton-ga"
    },
    {
        "State": "Georgia",
        "City": "Douglasville",
        "StateID": "GA",
        "url": "douglasville-ga"
    },
    {
        "State": "Georgia",
        "City": "Kennesaw",
        "StateID": "GA",
        "url": "kennesaw-ga"
    },
    {
        "State": "Georgia",
        "City": "Lawrenceville",
        "StateID": "GA",
        "url": "lawrenceville-ga"
    },
    {
        "State": "Georgia",
        "City": "Statesboro",
        "StateID": "GA",
        "url": "statesboro-ga"
    },
    {
        "State": "Georgia",
        "City": "Duluth",
        "StateID": "GA",
        "url": "duluth-ga"
    },
    {
        "State": "Georgia",
        "City": "Woodstock",
        "StateID": "GA",
        "url": "woodstock-ga"
    },
    {
        "State": "Georgia",
        "City": "Griffin",
        "StateID": "GA",
        "url": "griffin-ga"
    },
    {
        "State": "Georgia",
        "City": "Carrollton",
        "StateID": "GA",
        "url": "carrollton-ga"
    },
    {
        "State": "Georgia",
        "City": "Canton",
        "StateID": "GA",
        "url": "canton-ga"
    },
    {
        "State": "Georgia",
        "City": "McDonough",
        "StateID": "GA",
        "url": "mcdonough-ga"
    },
    {
        "State": "Georgia",
        "City": "Pooler",
        "StateID": "GA",
        "url": "pooler-ga"
    },
    {
        "State": "Georgia",
        "City": "Decatur",
        "StateID": "GA",
        "url": "decatur-ga"
    },
    {
        "State": "Georgia",
        "City": "Acworth",
        "StateID": "GA",
        "url": "acworth-ga"
    },
    {
        "State": "Georgia",
        "City": "Suwanee",
        "StateID": "GA",
        "url": "suwanee-ga"
    },
    {
        "State": "Georgia",
        "City": "Stockbridge",
        "StateID": "GA",
        "url": "stockbridge-ga"
    },
    {
        "State": "Georgia",
        "City": "Thomasville",
        "StateID": "GA",
        "url": "thomasville-ga"
    },
    {
        "State": "Georgia",
        "City": "Moultrie",
        "StateID": "GA",
        "url": "moultrie-ga"
    },
    {
        "State": "Georgia",
        "City": "Tifton",
        "StateID": "GA",
        "url": "tifton-ga"
    },
    {
        "State": "Georgia",
        "City": "Snellville",
        "StateID": "GA",
        "url": "snellville-ga"
    },
    {
        "State": "Georgia",
        "City": "Lithonia",
        "StateID": "GA",
        "url": "lithonia-ga"
    },
    {
        "State": "Georgia",
        "City": "Union City",
        "StateID": "GA",
        "url": "union-city-ga"
    },
    {
        "State": "Georgia",
        "City": "Loganville",
        "StateID": "GA",
        "url": "loganville-ga"
    },
    {
        "State": "Georgia",
        "City": "Riverdale",
        "StateID": "GA",
        "url": "riverdale-ga"
    },
    {
        "State": "Georgia",
        "City": "Norcross",
        "StateID": "GA",
        "url": "norcross-ga"
    },
    {
        "State": "Georgia",
        "City": "Conyers",
        "StateID": "GA",
        "url": "conyers-ga"
    },
    {
        "State": "Georgia",
        "City": "Milledgeville",
        "StateID": "GA",
        "url": "milledgeville-ga"
    },
    {
        "State": "Georgia",
        "City": "Kingsland",
        "StateID": "GA",
        "url": "kingsland-ga"
    },
    {
        "State": "Georgia",
        "City": "Chamblee",
        "StateID": "GA",
        "url": "chamblee-ga"
    },
    {
        "State": "Georgia",
        "City": "Jesup",
        "StateID": "GA",
        "url": "jesup-ga"
    },
    {
        "State": "Georgia",
        "City": "Calhoun",
        "StateID": "GA",
        "url": "calhoun-ga"
    },
    {
        "State": "Georgia",
        "City": "Monroe",
        "StateID": "GA",
        "url": "monroe-ga"
    },
    {
        "State": "Georgia",
        "City": "Fitzgerald",
        "StateID": "GA",
        "url": "fitzgerald-ga"
    },
    {
        "State": "Georgia",
        "City": "Bainbridge",
        "StateID": "GA",
        "url": "bainbridge-ga"
    },
    {
        "State": "Georgia",
        "City": "Vidalia",
        "StateID": "GA",
        "url": "vidalia-ga"
    },
    {
        "State": "Georgia",
        "City": "Waycross",
        "StateID": "GA",
        "url": "waycross-ga"
    },
    {
        "State": "Georgia",
        "City": "Cedartown",
        "StateID": "GA",
        "url": "cedartown-ga"
    },
    {
        "State": "Georgia",
        "City": "LaGrange",
        "StateID": "GA",
        "url": "lagrange-ga"
    },
    {
        "State": "Georgia",
        "City": "Dublin",
        "StateID": "GA",
        "url": "dublin-ga"
    },
    {
        "State": "Georgia",
        "City": "Perry",
        "StateID": "GA",
        "url": "perry-ga"
    },
    {
        "State": "Georgia",
        "City": "Camilla",
        "StateID": "GA",
        "url": "camilla-ga"
    },
    {
        "State": "Georgia",
        "City": "Forsyth",
        "StateID": "GA",
        "url": "forsyth-ga"
    },
    {
        "State": "Georgia",
        "City": "Cordele",
        "StateID": "GA",
        "url": "cordele-ga"
    },
    {
        "State": "Georgia",
        "City": "Brunswick",
        "StateID": "GA",
        "url": "brunswick-ga"
    },
    {
        "State": "Georgia",
        "City": "Cumming",
        "StateID": "GA",
        "url": "cumming-ga"
    },
    {
        "State": "Georgia",
        "City": "Commerce",
        "StateID": "GA",
        "url": "commerce-ga"
    },
    {
        "State": "Georgia",
        "City": "Fairburn",
        "StateID": "GA",
        "url": "fairburn-ga"
    },
    {
        "State": "Georgia",
        "City": "Clarkston",
        "StateID": "GA",
        "url": "clarkston-ga"
    },
    {
        "State": "Georgia",
        "City": "Fort Valley",
        "StateID": "GA",
        "url": "fort-valley-ga"
    },
    {
        "State": "Georgia",
        "City": "Covington",
        "StateID": "GA",
        "url": "covington-ga"
    },
    {
        "State": "Georgia",
        "City": "Oxford",
        "StateID": "GA",
        "url": "oxford-ga"
    },
    {
        "State": "Georgia",
        "City": "Eatonton",
        "StateID": "GA",
        "url": "eatonton-ga"
    },
    {
        "State": "Georgia",
        "City": "Madison",
        "StateID": "GA",
        "url": "madison-ga"
    },
    {
        "State": "Georgia",
        "City": "Dawson",
        "StateID": "GA",
        "url": "dawson-ga"
    },
    {
        "State": "Georgia",
        "City": "Thomaston",
        "StateID": "GA",
        "url": "thomaston-ga"
    },
    {
        "State": "Georgia",
        "City": "Mount Vernon",
        "StateID": "GA",
        "url": "mount-vernon-ga"
    },
    {
        "State": "Georgia",
        "City": "Winder",
        "StateID": "GA",
        "url": "winder-ga"
    },
    {
        "State": "Georgia",
        "City": "Americus",
        "StateID": "GA",
        "url": "americus-ga"
    },
    {
        "State": "Georgia",
        "City": "Douglas",
        "StateID": "GA",
        "url": "douglas-ga"
    },
    {
        "State": "Georgia",
        "City": "Jefferson",
        "StateID": "GA",
        "url": "jefferson-ga"
    },
    {
        "State": "Georgia",
        "City": "Hartwell",
        "StateID": "GA",
        "url": "hartwell-ga"
    },
    {
        "State": "Georgia",
        "City": "Jackson",
        "StateID": "GA",
        "url": "jackson-ga"
    },
    {
        "State": "Georgia",
        "City": "Jesup",
        "StateID": "GA",
        "url": "jesup-ga"
    },
    {
        "State": "Georgia",
        "City": "LaFayette",
        "StateID": "GA",
        "url": "lafayette-ga"
    },
    {
        "State": "Georgia",
        "City": "Sylvester",
        "StateID": "GA",
        "url": "sylvester-ga"
    },
    {
        "State": "Georgia",
        "City": "Toccoa",
        "StateID": "GA",
        "url": "toccoa-ga"
    },
    {
        "State": "Georgia",
        "City": "Dahlonega",
        "StateID": "GA",
        "url": "dahlonega-ga"
    },
    {
        "State": "Georgia",
        "City": "Hawkinsville",
        "StateID": "GA",
        "url": "hawkinsville-ga"
    },
    {
        "State": "Georgia",
        "City": "Cochran",
        "StateID": "GA",
        "url": "cochran-ga"
    },
    {
        "State": "Georgia",
        "City": "Hampton",
        "StateID": "GA",
        "url": "hampton-ga"
    },
    {
        "State": "Georgia",
        "City": "Lovejoy",
        "StateID": "GA",
        "url": "lovejoy-ga"
    },
    {
        "State": "Georgia",
        "City": "McRae",
        "StateID": "GA",
        "url": "mcrae-ga"
    },
    {
        "State": "Georgia",
        "City": "Nashville",
        "StateID": "GA",
        "url": "nashville-ga"
    },
    {
        "State": "Georgia",
        "City": "Newnan",
        "StateID": "GA",
        "url": "newnan-ga"
    },
    {
        "State": "Georgia",
        "City": "Adel",
        "StateID": "GA",
        "url": "adel-ga"
    },
    {
        "State": "Georgia",
        "City": "Greensboro",
        "StateID": "GA",
        "url": "greensboro-ga"
    },
    {
        "State": "Georgia",
        "City": "Metter",
        "StateID": "GA",
        "url": "metter-ga"
    },
    {
        "State": "Georgia",
        "City": "Quitman",
        "StateID": "GA",
        "url": "quitman-ga"
    },
    {
        "State": "Georgia",
        "City": "Baxley",
        "StateID": "GA",
        "url": "baxley-ga"
    },
    {
        "State": "Georgia",
        "City": "Royston",
        "StateID": "GA",
        "url": "royston-ga"
    },
    {
        "State": "Georgia",
        "City": "Sylvania",
        "StateID": "GA",
        "url": "sylvania-ga"
    },
    {
        "State": "Georgia",
        "City": "Tybee Island",
        "StateID": "GA",
        "url": "tybee-island-ga"
    },
    {
        "State": "Georgia",
        "City": "Vidalia",
        "StateID": "GA",
        "url": "vidalia-ga"
    },
    {
        "State": "Georgia",
        "City": "Washington",
        "StateID": "GA",
        "url": "washington-ga"
    },
    {
        "State": "Georgia",
        "City": "Wrightsville",
        "StateID": "GA",
        "url": "wrightsville-ga"
    },
    {
        "State": "Georgia",
        "City": "Ashburn",
        "StateID": "GA",
        "url": "ashburn-ga"
    },
    {
        "State": "Georgia",
        "City": "Bremen",
        "StateID": "GA",
        "url": "bremen-ga"
    },
    {
        "State": "Georgia",
        "City": "Chatsworth",
        "StateID": "GA",
        "url": "chatsworth-ga"
    },
    {
        "State": "Georgia",
        "City": "Claxton",
        "StateID": "GA",
        "url": "claxton-ga"
    },
    {
        "State": "Georgia",
        "City": "Dawsonville",
        "StateID": "GA",
        "url": "dawsonville-ga"
    },
    {
        "State": "Georgia",
        "City": "Hiawassee",
        "StateID": "GA",
        "url": "hiawassee-ga"
    },
    {
        "State": "Georgia",
        "City": "Irwinton",
        "StateID": "GA",
        "url": "irwinton-ga"
    },
    {
        "State": "Georgia",
        "City": "Lumpkin",
        "StateID": "GA",
        "url": "lumpkin-ga"
    },
    {
        "State": "Georgia",
        "City": "Midway",
        "StateID": "GA",
        "url": "midway-ga"
    },
    {
        "State": "Georgia",
        "City": "Monticello",
        "StateID": "GA",
        "url": "monticello-ga"
    },
    {
        "State": "Georgia",
        "City": "Nahunta",
        "StateID": "GA",
        "url": "nahunta-ga"
    },
    {
        "State": "Georgia",
        "City": "Pembroke",
        "StateID": "GA",
        "url": "pembroke-ga"
    },
    {
        "State": "Georgia",
        "City": "Reidsville",
        "StateID": "GA",
        "url": "reidsville-ga"
    },
    {
        "State": "Georgia",
        "City": "Sparta",
        "StateID": "GA",
        "url": "sparta-ga"
    },
    {
        "State": "Georgia",
        "City": "Summerville",
        "StateID": "GA",
        "url": "summerville-ga"
    },
    {
        "State": "Georgia",
        "City": "Swainsboro",
        "StateID": "GA",
        "url": "swainsboro-ga"
    },
    {
        "State": "Georgia",
        "City": "Trenton",
        "StateID": "GA",
        "url": "trenton-ga"
    },
    {
        "State": "Georgia",
        "City": "Twin City",
        "StateID": "GA",
        "url": "twin-city-ga"
    },
    {
        "State": "Georgia",
        "City": "Warrenton",
        "StateID": "GA",
        "url": "warrenton-ga"
    },
    {
        "State": "Georgia",
        "City": "Warwick",
        "StateID": "GA",
        "url": "warwick-ga"
    },
    {
        "State": "Georgia",
        "City": "Weston",
        "StateID": "GA",
        "url": "weston-ga"
    },
    {
        "State": "Georgia",
        "City": "Woodbury",
        "StateID": "GA",
        "url": "woodbury-ga"
    },
    {
        "State": "Georgia",
        "City": "Zebulon",
        "StateID": "GA",
        "url": "zebulon-ga"
    },
    {
        "State": "Georgia",
        "City": "Eastman",
        "StateID": "GA",
        "url": "eastman-ga"
    },
    {
        "State": "Georgia",
        "City": "Millen",
        "StateID": "GA",
        "url": "millen-ga"
    },
    {
        "State": "Georgia",
        "City": "Montrose",
        "StateID": "GA",
        "url": "montrose-ga"
    },
    {
        "State": "Georgia",
        "City": "Nashville",
        "StateID": "GA",
        "url": "nashville-ga"
    },
    {
        "State": "Georgia",
        "City": "Patterson",
        "StateID": "GA",
        "url": "patterson-ga"
    },
    {
        "State": "Georgia",
        "City": "Riceboro",
        "StateID": "GA",
        "url": "riceboro-ga"
    },
    {
        "State": "Georgia",
        "City": "Rossville",
        "StateID": "GA",
        "url": "rossville-ga"
    },
    {
        "State": "Georgia",
        "City": "Shellman",
        "StateID": "GA",
        "url": "shellman-ga"
    },
    {
        "State": "Georgia",
        "City": "Soperton",
        "StateID": "GA",
        "url": "soperton-ga"
    },
    {
        "State": "Georgia",
        "City": "Sparta",
        "StateID": "GA",
        "url": "sparta-ga"
    },
    {
        "State": "Georgia",
        "City": "Statham",
        "StateID": "GA",
        "url": "statham-ga"
    },
    {
        "State": "Georgia",
        "City": "Thomson",
        "StateID": "GA",
        "url": "thomson-ga"
    },
    {
        "State": "Georgia",
        "City": "Unadilla",
        "StateID": "GA",
        "url": "unadilla-ga"
    },
    {
        "State": "Georgia",
        "City": "Warrenton",
        "StateID": "GA",
        "url": "warrenton-ga"
    },
    {
        "State": "Georgia",
        "City": "Waynesboro",
        "StateID": "GA",
        "url": "waynesboro-ga"
    },
    {
        "State": "Georgia",
        "City": "Whitesburg",
        "StateID": "GA",
        "url": "whitesburg-ga"
    },
    {
        "State": "Georgia",
        "City": "Willacoochee",
        "StateID": "GA",
        "url": "willacoochee-ga"
    },
    {
        "State": "Georgia",
        "City": "Wrens",
        "StateID": "GA",
        "url": "wrens-ga"
    },
    {
        "State": "Georgia",
        "City": "Wrightsville",
        "StateID": "GA",
        "url": "wrightsville-ga"
    },
    {
        "State": "Massachusetts",
        "City": "Boston",
        "StateID": "MA",
        "url": "boston-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Worcester",
        "StateID": "MA",
        "url": "worcester-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Springfield",
        "StateID": "MA",
        "url": "springfield-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Lowell",
        "StateID": "MA",
        "url": "lowell-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Cambridge",
        "StateID": "MA",
        "url": "cambridge-ma"
    },
    {
        "State": "Massachusetts",
        "City": "New Bedford",
        "StateID": "MA",
        "url": "new-bedford-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Brockton",
        "StateID": "MA",
        "url": "brockton-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Quincy",
        "StateID": "MA",
        "url": "quincy-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Lynn",
        "StateID": "MA",
        "url": "lynn-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Fall River",
        "StateID": "MA",
        "url": "fall-river-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Newton",
        "StateID": "MA",
        "url": "newton-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Lawrence",
        "StateID": "MA",
        "url": "lawrence-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Somerville",
        "StateID": "MA",
        "url": "somerville-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Framingham",
        "StateID": "MA",
        "url": "framingham-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Haverhill",
        "StateID": "MA",
        "url": "haverhill-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Waltham",
        "StateID": "MA",
        "url": "waltham-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Malden",
        "StateID": "MA",
        "url": "malden-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Medford",
        "StateID": "MA",
        "url": "medford-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Taunton",
        "StateID": "MA",
        "url": "taunton-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Chicopee",
        "StateID": "MA",
        "url": "chicopee-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Weymouth",
        "StateID": "MA",
        "url": "weymouth-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Revere",
        "StateID": "MA",
        "url": "revere-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Peabody",
        "StateID": "MA",
        "url": "peabody-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Methuen",
        "StateID": "MA",
        "url": "methuen-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Everett",
        "StateID": "MA",
        "url": "everett-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Attleboro",
        "StateID": "MA",
        "url": "attleboro-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Salem",
        "StateID": "MA",
        "url": "salem-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Westfield",
        "StateID": "MA",
        "url": "westfield-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Leominster",
        "StateID": "MA",
        "url": "leominster-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Fitchburg",
        "StateID": "MA",
        "url": "fitchburg-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Beverly",
        "StateID": "MA",
        "url": "beverly-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Braintree",
        "StateID": "MA",
        "url": "braintree-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Marlborough",
        "StateID": "MA",
        "url": "marlborough-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Woburn",
        "StateID": "MA",
        "url": "woburn-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Chelsea",
        "StateID": "MA",
        "url": "chelsea-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Randolph",
        "StateID": "MA",
        "url": "randolph-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Watertown",
        "StateID": "MA",
        "url": "watertown-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Franklin",
        "StateID": "MA",
        "url": "franklin-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Amherst",
        "StateID": "MA",
        "url": "amherst-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Shrewsbury",
        "StateID": "MA",
        "url": "shrewsbury-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Dracut",
        "StateID": "MA",
        "url": "dracut-ma"
    },
    {
        "State": "Massachusetts",
        "City": "North Andover",
        "StateID": "MA",
        "url": "north-andover-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Tewksbury",
        "StateID": "MA",
        "url": "tewksbury-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Chelmsford",
        "StateID": "MA",
        "url": "chelmsford-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Natick",
        "StateID": "MA",
        "url": "natick-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Dartmouth",
        "StateID": "MA",
        "url": "dartmouth-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Norwood",
        "StateID": "MA",
        "url": "norwood-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Gloucester",
        "StateID": "MA",
        "url": "gloucester-ma"
    },
    {
        "State": "Massachusetts",
        "City": "West Springfield",
        "StateID": "MA",
        "url": "west-springfield-ma"
    },
    {
        "State": "Massachusetts",
        "City": "Agawam",
        "StateID": "MA",
        "url": "agawam-ma"
    },
    {
        "State": "North Carolina",
        "City": "Charlotte",
        "StateID": "NC",
        "url": "charlotte-nc"
    },
    {
        "State": "North Carolina",
        "City": "Raleigh",
        "StateID": "NC",
        "url": "raleigh-nc"
    },
    {
        "State": "North Carolina",
        "City": "Greensboro",
        "StateID": "NC",
        "url": "greensboro-nc"
    },
    {
        "State": "North Carolina",
        "City": "Durham",
        "StateID": "NC",
        "url": "durham-nc"
    },
    {
        "State": "North Carolina",
        "City": "Winston-Salem",
        "StateID": "NC",
        "url": "winston-salem-nc"
    },
    {
        "State": "North Carolina",
        "City": "Fayetteville",
        "StateID": "NC",
        "url": "fayetteville-nc"
    },
    {
        "State": "North Carolina",
        "City": "Cary",
        "StateID": "NC",
        "url": "cary-nc"
    },
    {
        "State": "North Carolina",
        "City": "Wilmington",
        "StateID": "NC",
        "url": "wilmington-nc"
    },
    {
        "State": "North Carolina",
        "City": "High Point",
        "StateID": "NC",
        "url": "high-point-nc"
    },
    {
        "State": "North Carolina",
        "City": "Greenville",
        "StateID": "NC",
        "url": "greenville-nc"
    },
    {
        "State": "North Carolina",
        "City": "Asheville",
        "StateID": "NC",
        "url": "asheville-nc"
    },
    {
        "State": "North Carolina",
        "City": "Concord",
        "StateID": "NC",
        "url": "concord-nc"
    },
    {
        "State": "North Carolina",
        "City": "Gastonia",
        "StateID": "NC",
        "url": "gastonia-nc"
    },
    {
        "State": "North Carolina",
        "City": "Jacksonville",
        "StateID": "NC",
        "url": "jacksonville-nc"
    },
    {
        "State": "North Carolina",
        "City": "Chapel Hill",
        "StateID": "NC",
        "url": "chapel-hill-nc"
    },
    {
        "State": "North Carolina",
        "City": "Rocky Mount",
        "StateID": "NC",
        "url": "rocky-mount-nc"
    },
    {
        "State": "North Carolina",
        "City": "Burlington",
        "StateID": "NC",
        "url": "burlington-nc"
    },
    {
        "State": "North Carolina",
        "City": "Wilson",
        "StateID": "NC",
        "url": "wilson-nc"
    },
    {
        "State": "North Carolina",
        "City": "Huntersville",
        "StateID": "NC",
        "url": "huntersville-nc"
    },
    {
        "State": "North Carolina",
        "City": "Kannapolis",
        "StateID": "NC",
        "url": "kannapolis-nc"
    },
    {
        "State": "North Carolina",
        "City": "Apex",
        "StateID": "NC",
        "url": "apex-nc"
    },
    {
        "State": "North Carolina",
        "City": "Hickory",
        "StateID": "NC",
        "url": "hickory-nc"
    },
    {
        "State": "North Carolina",
        "City": "Indian Trail",
        "StateID": "NC",
        "url": "indian-trail-nc"
    },
    {
        "State": "North Carolina",
        "City": "Mooresville",
        "StateID": "NC",
        "url": "mooresville-nc"
    },
    {
        "State": "North Carolina",
        "City": "Holly Springs",
        "StateID": "NC",
        "url": "holly-springs-nc"
    },
    {
        "State": "North Carolina",
        "City": "Monroe",
        "StateID": "NC",
        "url": "monroe-nc"
    },
    {
        "State": "North Carolina",
        "City": "Salisbury",
        "StateID": "NC",
        "url": "salisbury-nc"
    },
    {
        "State": "North Carolina",
        "City": "Matthews",
        "StateID": "NC",
        "url": "matthews-nc"
    },
    {
        "State": "North Carolina",
        "City": "Sanford",
        "StateID": "NC",
        "url": "sanford-nc"
    },
    {
        "State": "North Carolina",
        "City": "Cornelius",
        "StateID": "NC",
        "url": "cornelius-nc"
    },
    {
        "State": "North Carolina",
        "City": "Garner",
        "StateID": "NC",
        "url": "garner-nc"
    },
    {
        "State": "North Carolina",
        "City": "Statesville",
        "StateID": "NC",
        "url": "statesville-nc"
    },
    {
        "State": "North Carolina",
        "City": "Thomasville",
        "StateID": "NC",
        "url": "thomasville-nc"
    },
    {
        "State": "North Carolina",
        "City": "Goldsboro",
        "StateID": "NC",
        "url": "goldsboro-nc"
    },
    {
        "State": "North Carolina",
        "City": "Fuquay-Varina",
        "StateID": "NC",
        "url": "fuquay-varina-nc"
    },
    {
        "State": "North Carolina",
        "City": "Clayton",
        "StateID": "NC",
        "url": "clayton-nc"
    },
    {
        "State": "North Carolina",
        "City": "Wake Forest",
        "StateID": "NC",
        "url": "wake-forest-nc"
    },
    {
        "State": "North Carolina",
        "City": "Lenoir",
        "StateID": "NC",
        "url": "lenoir-nc"
    },
    {
        "State": "North Carolina",
        "City": "Lexington",
        "StateID": "NC",
        "url": "lexington-nc"
    },
    {
        "State": "North Carolina",
        "City": "Carrboro",
        "StateID": "NC",
        "url": "carrboro-nc"
    },
    {
        "State": "North Carolina",
        "City": "Kinston",
        "StateID": "NC",
        "url": "kinston-nc"
    },
    {
        "State": "North Carolina",
        "City": "Shelby",
        "StateID": "NC",
        "url": "shelby-nc"
    },
    {
        "State": "North Carolina",
        "City": "Lumberton",
        "StateID": "NC",
        "url": "lumberton-nc"
    },
    {
        "State": "North Carolina",
        "City": "Havelock",
        "StateID": "NC",
        "url": "havelock-nc"
    },
    {
        "State": "North Carolina",
        "City": "Morganton",
        "StateID": "NC",
        "url": "morganton-nc"
    },
    {
        "State": "North Carolina",
        "City": "Albemarle",
        "StateID": "NC",
        "url": "albemarle-nc"
    },
    {
        "State": "North Carolina",
        "City": "Tarboro",
        "StateID": "NC",
        "url": "tarboro-nc"
    },
    {
        "State": "North Carolina",
        "City": "Reidsville",
        "StateID": "NC",
        "url": "reidsville-nc"
    },
    {
        "State": "North Carolina",
        "City": "Henderson",
        "StateID": "NC",
        "url": "henderson-nc"
    },
    {
        "State": "North Carolina",
        "City": "Mount Holly",
        "StateID": "NC",
        "url": "mount-holly-nc"
    },
    {
        "State": "North Carolina",
        "City": "Newton",
        "StateID": "NC",
        "url": "newton-nc"
    },
    {
        "State": "North Carolina",
        "City": "Boone",
        "StateID": "NC",
        "url": "boone-nc"
    },
    {
        "State": "North Carolina",
        "City": "Laurinburg",
        "StateID": "NC",
        "url": "laurinburg-nc"
    },
    {
        "State": "North Carolina",
        "City": "Clinton",
        "StateID": "NC",
        "url": "clinton-nc"
    },
    {
        "State": "North Carolina",
        "City": "Harrisburg",
        "StateID": "NC",
        "url": "harrisburg-nc"
    },
    {
        "State": "North Carolina",
        "City": "Mebane",
        "StateID": "NC",
        "url": "mebane-nc"
    },
    {
        "State": "North Carolina",
        "City": "Roxboro",
        "StateID": "NC",
        "url": "roxboro-nc"
    },
    {
        "State": "North Carolina",
        "City": "Kings Mountain",
        "StateID": "NC",
        "url": "kings-mountain-nc"
    },
    {
        "State": "North Carolina",
        "City": "Eden",
        "StateID": "NC",
        "url": "eden-nc"
    },
    {
        "State": "North Carolina",
        "City": "Hope Mills",
        "StateID": "NC",
        "url": "hope-mills-nc"
    },
    {
        "State": "North Carolina",
        "City": "Stokesdale",
        "StateID": "NC",
        "url": "stokesdale-nc"
    },
    {
        "State": "North Carolina",
        "City": "Belmont",
        "StateID": "NC",
        "url": "belmont-nc"
    },
    {
        "State": "North Carolina",
        "City": "Lincolnton",
        "StateID": "NC",
        "url": "lincolnton-nc"
    },
    {
        "State": "North Carolina",
        "City": "Elkin",
        "StateID": "NC",
        "url": "elkin-nc"
    },
    {
        "State": "North Carolina",
        "City": "Smithfield",
        "StateID": "NC",
        "url": "smithfield-nc"
    },
    {
        "State": "North Carolina",
        "City": "Spring Lake",
        "StateID": "NC",
        "url": "spring-lake-nc"
    },
    {
        "State": "North Carolina",
        "City": "Brevard",
        "StateID": "NC",
        "url": "brevard-nc"
    },
    {
        "State": "North Carolina",
        "City": "Graham",
        "StateID": "NC",
        "url": "graham-nc"
    },
    {
        "State": "North Carolina",
        "City": "Southern Pines",
        "StateID": "NC",
        "url": "southern-pines-nc"
    },
    {
        "State": "North Carolina",
        "City": "Oxford",
        "StateID": "NC",
        "url": "oxford-nc"
    },
    {
        "State": "North Carolina",
        "City": "Archdale",
        "StateID": "NC",
        "url": "archdale-nc"
    },
    {
        "State": "North Carolina",
        "City": "Forest City",
        "StateID": "NC",
        "url": "forest-city-nc"
    },
    {
        "State": "North Carolina",
        "City": "Hendersonville",
        "StateID": "NC",
        "url": "hendersonville-nc"
    },
    {
        "State": "North Carolina",
        "City": "Pinehurst",
        "StateID": "NC",
        "url": "pinehurst-nc"
    },
    {
        "State": "North Carolina",
        "City": "Kernersville",
        "StateID": "NC",
        "url": "kernersville-nc"
    },
    {
        "State": "North Carolina",
        "City": "Raeford",
        "StateID": "NC",
        "url": "raeford-nc"
    },
    {
        "State": "North Carolina",
        "City": "Asheboro",
        "StateID": "NC",
        "url": "asheboro-nc"
    },
    {
        "State": "North Carolina",
        "City": "Black Mountain",
        "StateID": "NC",
        "url": "black-mountain-nc"
    },
    {
        "State": "North Carolina",
        "City": "Southport",
        "StateID": "NC",
        "url": "southport-nc"
    },
    {
        "State": "North Carolina",
        "City": "Swansboro",
        "StateID": "NC",
        "url": "swansboro-nc"
    },
    {
        "State": "North Carolina",
        "City": "Wadesboro",
        "StateID": "NC",
        "url": "wadesboro-nc"
    },
    {
        "State": "North Carolina",
        "City": "Zebulon",
        "StateID": "NC",
        "url": "zebulon-nc"
    },
    {
        "State": "North Carolina",
        "City": "Shallotte",
        "StateID": "NC",
        "url": "shallotte-nc"
    },
    {
        "State": "North Carolina",
        "City": "Kings Grant",
        "StateID": "NC",
        "url": "kings-grant-nc"
    },
    {
        "State": "North Carolina",
        "City": "Pineville",
        "StateID": "NC",
        "url": "pineville-nc"
    },
    {
        "State": "North Carolina",
        "City": "Lake Lure",
        "StateID": "NC",
        "url": "lake-lure-nc"
    },
    {
        "State": "North Carolina",
        "City": "Kitty Hawk",
        "StateID": "NC",
        "url": "kitty-hawk-nc"
    },
    {
        "State": "North Carolina",
        "City": "Fletcher",
        "StateID": "NC",
        "url": "fletcher-nc"
    },
    {
        "State": "North Carolina",
        "City": "Waynesville",
        "StateID": "NC",
        "url": "waynesville-nc"
    },
    {
        "State": "North Carolina",
        "City": "North Wilkesboro",
        "StateID": "NC",
        "url": "north-wilkesboro-nc"
    },
    {
        "State": "North Carolina",
        "City": "Carrboro",
        "StateID": "NC",
        "url": "carrboro-nc"
    },
    {
        "State": "North Carolina",
        "City": "Franklin",
        "StateID": "NC",
        "url": "franklin-nc"
    },
    {
        "State": "North Carolina",
        "City": "Highlands",
        "StateID": "NC",
        "url": "highlands-nc"
    },
    {
        "State": "North Carolina",
        "City": "Kill Devil Hills",
        "StateID": "NC",
        "url": "kill-devil-hills-nc"
    },
    {
        "State": "North Carolina",
        "City": "Carolina Beach",
        "StateID": "NC",
        "url": "carolina-beach-nc"
    },
    {
        "State": "North Carolina",
        "City": "Sunset Beach",
        "StateID": "NC",
        "url": "sunset-beach-nc"
    },
    {
        "State": "North Carolina",
        "City": "Oak Island",
        "StateID": "NC",
        "url": "oak-island-nc"
    },
    {
        "State": "North Carolina",
        "City": "Maggie Valley",
        "StateID": "NC",
        "url": "maggie-valley-nc"
    },
    {
        "State": "North Carolina",
        "City": "Carthage",
        "StateID": "NC",
        "url": "carthage-nc"
    },
    {
        "State": "North Carolina",
        "City": "Edenton",
        "StateID": "NC",
        "url": "edenton-nc"
    },
    {
        "State": "North Carolina",
        "City": "Whiteville",
        "StateID": "NC",
        "url": "whiteville-nc"
    },
    {
        "State": "North Carolina",
        "City": "Highlands",
        "StateID": "NC",
        "url": "highlands-nc"
    },
    {
        "State": "North Carolina",
        "City": "Murphy",
        "StateID": "NC",
        "url": "murphy-nc"
    },
    {
        "State": "North Carolina",
        "City": "Bryson City",
        "StateID": "NC",
        "url": "bryson-city-nc"
    },
    {
        "State": "North Carolina",
        "City": "Nashville",
        "StateID": "NC",
        "url": "nashville-nc"
    },
    {
        "State": "North Carolina",
        "City": "Elizabethtown",
        "StateID": "NC",
        "url": "elizabethtown-nc"
    },
    {
        "State": "North Carolina",
        "City": "Beaufort",
        "StateID": "NC",
        "url": "beaufort-nc"
    },
    {
        "State": "North Carolina",
        "City": "Roanoke Rapids",
        "StateID": "NC",
        "url": "roanoke-rapids-nc"
    },
    {
        "State": "North Carolina",
        "City": "Morehead City",
        "StateID": "NC",
        "url": "morehead-city-nc"
    },
    {
        "State": "North Carolina",
        "City": "Brevard",
        "StateID": "NC",
        "url": "brevard-nc"
    },
    {
        "State": "North Carolina",
        "City": "Williamston",
        "StateID": "NC",
        "url": "williamston-nc"
    },
    {
        "State": "North Carolina",
        "City": "Rutherfordton",
        "StateID": "NC",
        "url": "rutherfordton-nc"
    },
    {
        "State": "North Carolina",
        "City": "Bayboro",
        "StateID": "NC",
        "url": "bayboro-nc"
    },
    {
        "State": "North Carolina",
        "City": "Canton",
        "StateID": "NC",
        "url": "canton-nc"
    },
    {
        "State": "North Carolina",
        "City": "Mars Hill",
        "StateID": "NC",
        "url": "mars-hill-nc"
    },
    {
        "State": "North Carolina",
        "City": "Shallotte",
        "StateID": "NC",
        "url": "shallotte-nc"
    },
    {
        "State": "North Carolina",
        "City": "Warrenton",
        "StateID": "NC",
        "url": "warrenton-nc"
    },
    {
        "State": "North Carolina",
        "City": "Mount Airy",
        "StateID": "NC",
        "url": "mount-airy-nc"
    },
    {
        "State": "North Carolina",
        "City": "Southern Shores",
        "StateID": "NC",
        "url": "southern-shores-nc"
    },
    {
        "State": "North Carolina",
        "City": "Boone",
        "StateID": "NC",
        "url": "boone-nc"
    },
    {
        "State": "North Carolina",
        "City": "Yadkinville",
        "StateID": "NC",
        "url": "yadkinville-nc"
    },
    {
        "State": "North Carolina",
        "City": "Plymouth",
        "StateID": "NC",
        "url": "plymouth-nc"
    },
    {
        "State": "North Carolina",
        "City": "Dobson",
        "StateID": "NC",
        "url": "dobson-nc"
    },
    {
        "State": "North Carolina",
        "City": "Walnut Cove",
        "StateID": "NC",
        "url": "walnut-cove-nc"
    },
    {
        "State": "North Carolina",
        "City": "Murphy",
        "StateID": "NC",
        "url": "murphy-nc"
    },
    {
        "State": "North Carolina",
        "City": "Waynesville",
        "StateID": "NC",
        "url": "waynesville-nc"
    },
    {
        "State": "North Carolina",
        "City": "Weaverville",
        "StateID": "NC",
        "url": "weaverville-nc"
    },
    {
        "State": "North Carolina",
        "City": "Siler City",
        "StateID": "NC",
        "url": "siler-city-nc"
    },
    {
        "State": "North Carolina",
        "City": "North Topsail Beach",
        "StateID": "NC",
        "url": "north-topsail-beach-nc"
    },
    {
        "State": "North Carolina",
        "City": "Wilmington",
        "StateID": "NC",
        "url": "wilmington-nc"
    },
    {
        "State": "North Carolina",
        "City": "Wilson",
        "StateID": "NC",
        "url": "wilson-nc"
    },
    {
        "State": "North Carolina",
        "City": "Winterville",
        "StateID": "NC",
        "url": "winterville-nc"
    },
    {
        "State": "North Carolina",
        "City": "Yanceyville",
        "StateID": "NC",
        "url": "yanceyville-nc"
    },
    {
        "State": "North Carolina",
        "City": "Youngsville",
        "StateID": "NC",
        "url": "youngsville-nc"
    },
    {
        "State": "North Carolina",
        "City": "Zebulon",
        "StateID": "NC",
        "url": "zebulon-nc"
    },
    {
        "State": "North Carolina",
        "City": "Belmont",
        "StateID": "NC",
        "url": "belmont-nc"
    },
    {
        "State": "North Carolina",
        "City": "Blowing Rock",
        "StateID": "NC",
        "url": "blowing-rock-nc"
    },
    {
        "State": "North Carolina",
        "City": "Carolina Shores",
        "StateID": "NC",
        "url": "carolina-shores-nc"
    },
    {
        "State": "North Carolina",
        "City": "Clyde",
        "StateID": "NC",
        "url": "clyde-nc"
    },
    {
        "State": "North Carolina",
        "City": "Cramerton",
        "StateID": "NC",
        "url": "cramerton-nc"
    },
    {
        "State": "North Carolina",
        "City": "Davidson",
        "StateID": "NC",
        "url": "davidson-nc"
    },
    {
        "State": "North Carolina",
        "City": "Duck",
        "StateID": "NC",
        "url": "duck-nc"
    },
    {
        "State": "North Carolina",
        "City": "Elon",
        "StateID": "NC",
        "url": "elon-nc"
    },
    {
        "State": "North Carolina",
        "City": "Four Oaks",
        "StateID": "NC",
        "url": "four-oaks-nc"
    },
    {
        "State": "North Carolina",
        "City": "Granville",
        "StateID": "NC",
        "url": "granville-nc"
    },
    {
        "State": "North Carolina",
        "City": "Green Level",
        "StateID": "NC",
        "url": "green-level-nc"
    },
    {
        "State": "North Carolina",
        "City": "Grifton",
        "StateID": "NC",
        "url": "grifton-nc"
    },
    {
        "State": "North Carolina",
        "City": "Holly Ridge",
        "StateID": "NC",
        "url": "holly-ridge-nc"
    },
    {
        "State": "North Carolina",
        "City": "Jamestown",
        "StateID": "NC",
        "url": "jamestown-nc"
    },
    {
        "State": "North Carolina",
        "City": "Kenly",
        "StateID": "NC",
        "url": "kenly-nc"
    },
    {
        "State": "Nevada",
        "City": "Las Vegas",
        "StateID": "NV",
        "url": "las-vegas-nv"
    },
    {
        "State": "Nevada",
        "City": "Henderson",
        "StateID": "NV",
        "url": "henderson-nv"
    },
    {
        "State": "Nevada",
        "City": "Reno",
        "StateID": "NV",
        "url": "reno-nv"
    },
    {
        "State": "Nevada",
        "City": "North Las Vegas",
        "StateID": "NV",
        "url": "north-las-vegas-nv"
    },
    {
        "State": "Nevada",
        "City": "Sparks",
        "StateID": "NV",
        "url": "sparks-nv"
    },
    {
        "State": "Nevada",
        "City": "Carson City",
        "StateID": "NV",
        "url": "carson-city-nv"
    },
    {
        "State": "Nevada",
        "City": "Elko",
        "StateID": "NV",
        "url": "elko-nv"
    },
    {
        "State": "Nevada",
        "City": "Mesquite",
        "StateID": "NV",
        "url": "mesquite-nv"
    },
    {
        "State": "Nevada",
        "City": "Boulder City",
        "StateID": "NV",
        "url": "boulder-city-nv"
    },
    {
        "State": "Nevada",
        "City": "Winnemucca",
        "StateID": "NV",
        "url": "winnemucca-nv"
    },
    {
        "State": "Nevada",
        "City": "Fallon",
        "StateID": "NV",
        "url": "fallon-nv"
    },
    {
        "State": "Nevada",
        "City": "West Wendover",
        "StateID": "NV",
        "url": "west-wendover-nv"
    },
    {
        "State": "Nevada",
        "City": "Ely",
        "StateID": "NV",
        "url": "ely-nv"
    },
    {
        "State": "Nevada",
        "City": "Lovelock",
        "StateID": "NV",
        "url": "lovelock-nv"
    },
    {
        "State": "Nevada",
        "City": "Yerington",
        "StateID": "NV",
        "url": "yerington-nv"
    },
    {
        "State": "Nevada",
        "City": "Wells",
        "StateID": "NV",
        "url": "wells-nv"
    },
    {
        "State": "Nevada",
        "City": "Caliente",
        "StateID": "NV",
        "url": "caliente-nv"
    },
    {
        "State": "Nevada",
        "City": "Fernley",
        "StateID": "NV",
        "url": "fernley-nv"
    },
    {
        "State": "Minnesota",
        "City": "Minneapolis",
        "StateID": "MN",
        "url": "minneapolis-mn"
    },
    {
        "State": "Minnesota",
        "City": "Saint Paul",
        "StateID": "MN",
        "url": "saint-paul-mn"
    },
    {
        "State": "Minnesota",
        "City": "Rochester",
        "StateID": "MN",
        "url": "rochester-mn"
    },
    {
        "State": "Minnesota",
        "City": "Duluth",
        "StateID": "MN",
        "url": "duluth-mn"
    },
    {
        "State": "Minnesota",
        "City": "Bloomington",
        "StateID": "MN",
        "url": "bloomington-mn"
    },
    {
        "State": "Minnesota",
        "City": "Brooklyn Park",
        "StateID": "MN",
        "url": "brooklyn-park-mn"
    },
    {
        "State": "Minnesota",
        "City": "Plymouth",
        "StateID": "MN",
        "url": "plymouth-mn"
    },
    {
        "State": "Minnesota",
        "City": "Maple Grove",
        "StateID": "MN",
        "url": "maple-grove-mn"
    },
    {
        "State": "Minnesota",
        "City": "Woodbury",
        "StateID": "MN",
        "url": "woodbury-mn"
    },
    {
        "State": "Minnesota",
        "City": "Eagan",
        "StateID": "MN",
        "url": "eagan-mn"
    },
    {
        "State": "Minnesota",
        "City": "Eden Prairie",
        "StateID": "MN",
        "url": "eden-prairie-mn"
    },
    {
        "State": "Minnesota",
        "City": "Burnsville",
        "StateID": "MN",
        "url": "burnsville-mn"
    },
    {
        "State": "Minnesota",
        "City": "Blaine",
        "StateID": "MN",
        "url": "blaine-mn"
    },
    {
        "State": "Minnesota",
        "City": "Lakeville",
        "StateID": "MN",
        "url": "lakeville-mn"
    },
    {
        "State": "Minnesota",
        "City": "Minnetonka",
        "StateID": "MN",
        "url": "minnetonka-mn"
    },
    {
        "State": "Minnesota",
        "City": "Apple Valley",
        "StateID": "MN",
        "url": "apple-valley-mn"
    },
    {
        "State": "Minnesota",
        "City": "Edina",
        "StateID": "MN",
        "url": "edina-mn"
    },
    {
        "State": "Minnesota",
        "City": "Saint Louis Park",
        "StateID": "MN",
        "url": "saint-louis-park-mn"
    },
    {
        "State": "Minnesota",
        "City": "Moorhead",
        "StateID": "MN",
        "url": "moorhead-mn"
    },
    {
        "State": "Minnesota",
        "City": "Shakopee",
        "StateID": "MN",
        "url": "shakopee-mn"
    },
    {
        "State": "Minnesota",
        "City": "Coon Rapids",
        "StateID": "MN",
        "url": "coon-rapids-mn"
    },
    {
        "State": "Minnesota",
        "City": "Maplewood",
        "StateID": "MN",
        "url": "maplewood-mn"
    },
    {
        "State": "Minnesota",
        "City": "Cottage Grove",
        "StateID": "MN",
        "url": "cottage-grove-mn"
    },
    {
        "State": "Minnesota",
        "City": "Richfield",
        "StateID": "MN",
        "url": "richfield-mn"
    },
    {
        "State": "Minnesota",
        "City": "Roseville",
        "StateID": "MN",
        "url": "roseville-mn"
    },
    {
        "State": "Minnesota",
        "City": "Andover",
        "StateID": "MN",
        "url": "andover-mn"
    },
    {
        "State": "Minnesota",
        "City": "Savage",
        "StateID": "MN",
        "url": "savage-mn"
    },
    {
        "State": "Minnesota",
        "City": "Brooklyn Center",
        "StateID": "MN",
        "url": "brooklyn-center-mn"
    },
    {
        "State": "Minnesota",
        "City": "Inver Grove Heights",
        "StateID": "MN",
        "url": "inver-grove-heights-mn"
    },
    {
        "State": "Minnesota",
        "City": "Ramsey",
        "StateID": "MN",
        "url": "ramsey-mn"
    },
    {
        "State": "Minnesota",
        "City": "Fridley",
        "StateID": "MN",
        "url": "fridley-mn"
    },
    {
        "State": "Minnesota",
        "City": "Prior Lake",
        "StateID": "MN",
        "url": "prior-lake-mn"
    },
    {
        "State": "Minnesota",
        "City": "Hastings",
        "StateID": "MN",
        "url": "hastings-mn"
    },
    {
        "State": "Minnesota",
        "City": "White Bear Lake",
        "StateID": "MN",
        "url": "white-bear-lake-mn"
    },
    {
        "State": "Minnesota",
        "City": "Chaska",
        "StateID": "MN",
        "url": "chaska-mn"
    },
    {
        "State": "Minnesota",
        "City": "Faribault",
        "StateID": "MN",
        "url": "faribault-mn"
    },
    {
        "State": "Minnesota",
        "City": "Elk River",
        "StateID": "MN",
        "url": "elk-river-mn"
    },
    {
        "State": "Minnesota",
        "City": "Saint Cloud",
        "StateID": "MN",
        "url": "saint-cloud-mn"
    },
    {
        "State": "Minnesota",
        "City": "Winona",
        "StateID": "MN",
        "url": "winona-mn"
    },
    {
        "State": "Minnesota",
        "City": "Mankato",
        "StateID": "MN",
        "url": "mankato-mn"
    },
    {
        "State": "Minnesota",
        "City": "Chanhassen",
        "StateID": "MN",
        "url": "chanhassen-mn"
    },
    {
        "State": "Minnesota",
        "City": "Buffalo",
        "StateID": "MN",
        "url": "buffalo-mn"
    },
    {
        "State": "Minnesota",
        "City": "Northfield",
        "StateID": "MN",
        "url": "northfield-mn"
    },
    {
        "State": "Minnesota",
        "City": "New Brighton",
        "StateID": "MN",
        "url": "new-brighton-mn"
    },
    {
        "State": "Minnesota",
        "City": "Otsego",
        "StateID": "MN",
        "url": "otsego-mn"
    },
    {
        "State": "Minnesota",
        "City": "Owatonna",
        "StateID": "MN",
        "url": "owatonna-mn"
    },
    {
        "State": "Minnesota",
        "City": "Albert Lea",
        "StateID": "MN",
        "url": "albert-lea-mn"
    },
    {
        "State": "Minnesota",
        "City": "Shoreview",
        "StateID": "MN",
        "url": "shoreview-mn"
    },
    {
        "State": "Minnesota",
        "City": "East Bethel",
        "StateID": "MN",
        "url": "east-bethel-mn"
    },
    {
        "State": "Minnesota",
        "City": "Austin",
        "StateID": "MN",
        "url": "austin-mn"
    },
    {
        "State": "Minnesota",
        "City": "Lino Lakes",
        "StateID": "MN",
        "url": "lino-lakes-mn"
    },
    {
        "State": "Minnesota",
        "City": "Hutchinson",
        "StateID": "MN",
        "url": "hutchinson-mn"
    },
    {
        "State": "Minnesota",
        "City": "Ham Lake",
        "StateID": "MN",
        "url": "ham-lake-mn"
    },
    {
        "State": "Minnesota",
        "City": "Monticello",
        "StateID": "MN",
        "url": "monticello-mn"
    },
    {
        "State": "Minnesota",
        "City": "Sartell",
        "StateID": "MN",
        "url": "sartell-mn"
    },
    {
        "State": "Minnesota",
        "City": "Waconia",
        "StateID": "MN",
        "url": "waconia-mn"
    },
    {
        "State": "Minnesota",
        "City": "Rogers",
        "StateID": "MN",
        "url": "rogers-mn"
    },
    {
        "State": "Minnesota",
        "City": "Marshall",
        "StateID": "MN",
        "url": "marshall-mn"
    },
    {
        "State": "Minnesota",
        "City": "Big Lake",
        "StateID": "MN",
        "url": "big-lake-mn"
    },
    {
        "State": "Minnesota",
        "City": "South Saint Paul",
        "StateID": "MN",
        "url": "south-saint-paul-mn"
    },
    {
        "State": "Minnesota",
        "City": "North Saint Paul",
        "StateID": "MN",
        "url": "north-saint-paul-mn"
    },
    {
        "State": "Minnesota",
        "City": "Detroit Lakes",
        "StateID": "MN",
        "url": "detroit-lakes-mn"
    },
    {
        "State": "Minnesota",
        "City": "Forest Lake",
        "StateID": "MN",
        "url": "forest-lake-mn"
    },
    {
        "State": "Minnesota",
        "City": "Jordan",
        "StateID": "MN",
        "url": "jordan-mn"
    },
    {
        "State": "Minnesota",
        "City": "Isanti",
        "StateID": "MN",
        "url": "isanti-mn"
    },
    {
        "State": "Minnesota",
        "City": "Delano",
        "StateID": "MN",
        "url": "delano-mn"
    },
    {
        "State": "Minnesota",
        "City": "Baxter",
        "StateID": "MN",
        "url": "baxter-mn"
    },
    {
        "State": "Minnesota",
        "City": "Red Wing",
        "StateID": "MN",
        "url": "red-wing-mn"
    },
    {
        "State": "Minnesota",
        "City": "Alexandria",
        "StateID": "MN",
        "url": "alexandria-mn"
    },
    {
        "State": "Minnesota",
        "City": "Fergus Falls",
        "StateID": "MN",
        "url": "fergus-falls-mn"
    },
    {
        "State": "Minnesota",
        "City": "New Ulm",
        "StateID": "MN",
        "url": "new-ulm-mn"
    },
    {
        "State": "Minnesota",
        "City": "Little Falls",
        "StateID": "MN",
        "url": "little-falls-mn"
    },
    {
        "State": "Minnesota",
        "City": "Glencoe",
        "StateID": "MN",
        "url": "glencoe-mn"
    },
    {
        "State": "Minnesota",
        "City": "Thief River Falls",
        "StateID": "MN",
        "url": "thief-river-falls-mn"
    },
    {
        "State": "Minnesota",
        "City": "Waseca",
        "StateID": "MN",
        "url": "waseca-mn"
    },
    {
        "State": "Minnesota",
        "City": "Saint Michael",
        "StateID": "MN",
        "url": "saint-michael-mn"
    },
    {
        "State": "Minnesota",
        "City": "Bemidji",
        "StateID": "MN",
        "url": "bemidji-mn"
    },
    {
        "State": "Minnesota",
        "City": "Waite Park",
        "StateID": "MN",
        "url": "waite-park-mn"
    },
    {
        "State": "Minnesota",
        "City": "Cloquet",
        "StateID": "MN",
        "url": "cloquet-mn"
    },
    {
        "State": "Minnesota",
        "City": "Grand Rapids",
        "StateID": "MN",
        "url": "grand-rapids-mn"
    },
    {
        "State": "Minnesota",
        "City": "Brainerd",
        "StateID": "MN",
        "url": "brainerd-mn"
    },
    {
        "State": "Minnesota",
        "City": "Spring Lake Park",
        "StateID": "MN",
        "url": "spring-lake-park-mn"
    },
    {
        "State": "Minnesota",
        "City": "Stillwater",
        "StateID": "MN",
        "url": "stillwater-mn"
    },
    {
        "State": "Minnesota",
        "City": "Hibbing",
        "StateID": "MN",
        "url": "hibbing-mn"
    },
    {
        "State": "Minnesota",
        "City": "Fairmont",
        "StateID": "MN",
        "url": "fairmont-mn"
    },
    {
        "State": "Minnesota",
        "City": "Morris",
        "StateID": "MN",
        "url": "morris-mn"
    },
    {
        "State": "Minnesota",
        "City": "Orono",
        "StateID": "MN",
        "url": "orono-mn"
    },
    {
        "State": "Minnesota",
        "City": "Byron",
        "StateID": "MN",
        "url": "byron-mn"
    },
    {
        "State": "Minnesota",
        "City": "Sauk Rapids",
        "StateID": "MN",
        "url": "sauk-rapids-mn"
    },
    {
        "State": "Minnesota",
        "City": "Virginia",
        "StateID": "MN",
        "url": "virginia-mn"
    },
    {
        "State": "Minnesota",
        "City": "Roseau",
        "StateID": "MN",
        "url": "roseau-mn"
    },
    {
        "State": "Minnesota",
        "City": "Pequot Lakes",
        "StateID": "MN",
        "url": "pequot-lakes-mn"
    },
    {
        "State": "Minnesota",
        "City": "Montevideo",
        "StateID": "MN",
        "url": "montevideo-mn"
    },
    {
        "State": "Minnesota",
        "City": "Two Harbors",
        "StateID": "MN",
        "url": "two-harbors-mn"
    },
    {
        "State": "Minnesota",
        "City": "Aitkin",
        "StateID": "MN",
        "url": "aitkin-mn"
    },
    {
        "State": "Minnesota",
        "City": "Ely",
        "StateID": "MN",
        "url": "ely-mn"
    },
    {
        "State": "Minnesota",
        "City": "Little Canada",
        "StateID": "MN",
        "url": "little-canada-mn"
    },
    {
        "State": "Minnesota",
        "City": "Maple Lake",
        "StateID": "MN",
        "url": "maple-lake-mn"
    },
    {
        "State": "Minnesota",
        "City": "Lake City",
        "StateID": "MN",
        "url": "lake-city-mn"
    },
    {
        "State": "Minnesota",
        "City": "North Oaks",
        "StateID": "MN",
        "url": "north-oaks-mn"
    },
    {
        "State": "Minnesota",
        "City": "Luverne",
        "StateID": "MN",
        "url": "luverne-mn"
    },
    {
        "State": "Minnesota",
        "City": "Annandale",
        "StateID": "MN",
        "url": "annandale-mn"
    },
    {
        "State": "Minnesota",
        "City": "Litchfield",
        "StateID": "MN",
        "url": "litchfield-mn"
    },
    {
        "State": "Minnesota",
        "City": "North Branch",
        "StateID": "MN",
        "url": "north-branch-mn"
    },
    {
        "State": "Minnesota",
        "City": "Cambridge",
        "StateID": "MN",
        "url": "cambridge-mn"
    },
    {
        "State": "Minnesota",
        "City": "Cold Spring",
        "StateID": "MN",
        "url": "cold-spring-mn"
    },
    {
        "State": "Minnesota",
        "City": "Paynesville",
        "StateID": "MN",
        "url": "paynesville-mn"
    },
    {
        "State": "Minnesota",
        "City": "Kasson",
        "StateID": "MN",
        "url": "kasson-mn"
    },
    {
        "State": "Minnesota",
        "City": "Worthington",
        "StateID": "MN",
        "url": "worthington-mn"
    },
    {
        "State": "Minnesota",
        "City": "Pipestone",
        "StateID": "MN",
        "url": "pipestone-mn"
    },
    {
        "State": "Minnesota",
        "City": "Walker",
        "StateID": "MN",
        "url": "walker-mn"
    },
    {
        "State": "Minnesota",
        "City": "Perham",
        "StateID": "MN",
        "url": "perham-mn"
    },
    {
        "State": "Minnesota",
        "City": "Caledonia",
        "StateID": "MN",
        "url": "caledonia-mn"
    },
    {
        "State": "Minnesota",
        "City": "Osakis",
        "StateID": "MN",
        "url": "osakis-mn"
    },
    {
        "State": "Minnesota",
        "City": "Lake Crystal",
        "StateID": "MN",
        "url": "lake-crystal-mn"
    },
    {
        "State": "Minnesota",
        "City": "Silver Bay",
        "StateID": "MN",
        "url": "silver-bay-mn"
    },
    {
        "State": "Minnesota",
        "City": "Redwood Falls",
        "StateID": "MN",
        "url": "redwood-falls-mn"
    },
    {
        "State": "Minnesota",
        "City": "Saint Peter",
        "StateID": "MN",
        "url": "saint-peter-mn"
    },
    {
        "State": "Minnesota",
        "City": "Foley",
        "StateID": "MN",
        "url": "foley-mn"
    },
    {
        "State": "Minnesota",
        "City": "Mora",
        "StateID": "MN",
        "url": "mora-mn"
    },
    {
        "State": "Minnesota",
        "City": "Milaca",
        "StateID": "MN",
        "url": "milaca-mn"
    },
    {
        "State": "Minnesota",
        "City": "New London",
        "StateID": "MN",
        "url": "new-london-mn"
    },
    {
        "State": "Minnesota",
        "City": "Saint James",
        "StateID": "MN",
        "url": "saint-james-mn"
    },
    {
        "State": "Minnesota",
        "City": "Jackson",
        "StateID": "MN",
        "url": "jackson-mn"
    },
    {
        "State": "Minnesota",
        "City": "Rush City",
        "StateID": "MN",
        "url": "rush-city-mn"
    },
    {
        "State": "Minnesota",
        "City": "Mapleton",
        "StateID": "MN",
        "url": "mapleton-mn"
    },
    {
        "State": "Minnesota",
        "City": "Chisago City",
        "StateID": "MN",
        "url": "chisago-city-mn"
    },
    {
        "State": "Minnesota",
        "City": "Warroad",
        "StateID": "MN",
        "url": "warroad-mn"
    },
    {
        "State": "Minnesota",
        "City": "Madison Lake",
        "StateID": "MN",
        "url": "madison-lake-mn"
    },
    {
        "State": "Minnesota",
        "City": "Blue Earth",
        "StateID": "MN",
        "url": "blue-earth-mn"
    },
    {
        "State": "Minnesota",
        "City": "Wadena",
        "StateID": "MN",
        "url": "wadena-mn"
    },
    {
        "State": "Minnesota",
        "City": "Mound",
        "StateID": "MN",
        "url": "mound-mn"
    },
    {
        "State": "Minnesota",
        "City": "Cannon Falls",
        "StateID": "MN",
        "url": "cannon-falls-mn"
    },
    {
        "State": "Minnesota",
        "City": "Elko New Market",
        "StateID": "MN",
        "url": "elko-new-market-mn"
    },
    {
        "State": "Minnesota",
        "City": "Montrose",
        "StateID": "MN",
        "url": "montrose-mn"
    },
    {
        "State": "Minnesota",
        "City": "Medford",
        "StateID": "MN",
        "url": "medford-mn"
    },
    {
        "State": "Minnesota",
        "City": "Le Center",
        "StateID": "MN",
        "url": "le-center-mn"
    },
    {
        "State": "Minnesota",
        "City": "Sleepy Eye",
        "StateID": "MN",
        "url": "sleepy-eye-mn"
    },
    {
        "State": "Minnesota",
        "City": "Belle Plaine",
        "StateID": "MN",
        "url": "belle-plaine-mn"
    },
    {
        "State": "Minnesota",
        "City": "Kenyon",
        "StateID": "MN",
        "url": "kenyon-mn"
    },
    {
        "State": "Minnesota",
        "City": "Plainview",
        "StateID": "MN",
        "url": "plainview-mn"
    },
    {
        "State": "Minnesota",
        "City": "Rockford",
        "StateID": "MN",
        "url": "rockford-mn"
    },
    {
        "State": "Minnesota",
        "City": "Pierz",
        "StateID": "MN",
        "url": "pierz-mn"
    },
    {
        "State": "Minnesota",
        "City": "Baudette",
        "StateID": "MN",
        "url": "baudette-mn"
    },
    {
        "State": "Minnesota",
        "City": "Eyota",
        "StateID": "MN",
        "url": "eyota-mn"
    },
    {
        "State": "Minnesota",
        "City": "Zumbrota",
        "StateID": "MN",
        "url": "zumbrota-mn"
    },
    {
        "State": "Minnesota",
        "City": "Blooming Prairie",
        "StateID": "MN",
        "url": "blooming-prairie-mn"
    },
    {
        "State": "Minnesota",
        "City": "Clara City",
        "StateID": "MN",
        "url": "clara-city-mn"
    },
    {
        "State": "Minnesota",
        "City": "Saint Bonifacius",
        "StateID": "MN",
        "url": "saint-bonifacius-mn"
    },
    {
        "State": "Minnesota",
        "City": "Kenyon",
        "StateID": "MN",
        "url": "kenyon-mn"
    },
    {
        "State": "Michigan",
        "City": "Detroit",
        "StateID": "MI",
        "url": "detroit-mi"
    },
    {
        "State": "Michigan",
        "City": "Grand Rapids",
        "StateID": "MI",
        "url": "grand-rapids-mi"
    },
    {
        "State": "Michigan",
        "City": "Warren",
        "StateID": "MI",
        "url": "warren-mi"
    },
    {
        "State": "Michigan",
        "City": "Sterling Heights",
        "StateID": "MI",
        "url": "sterling-heights-mi"
    },
    {
        "State": "Michigan",
        "City": "Ann Arbor",
        "StateID": "MI",
        "url": "ann-arbor-mi"
    },
    {
        "State": "Michigan",
        "City": "Lansing",
        "StateID": "MI",
        "url": "lansing-mi"
    },
    {
        "State": "Michigan",
        "City": "Flint",
        "StateID": "MI",
        "url": "flint-mi"
    },
    {
        "State": "Michigan",
        "City": "Dearborn",
        "StateID": "MI",
        "url": "dearborn-mi"
    },
    {
        "State": "Michigan",
        "City": "Livonia",
        "StateID": "MI",
        "url": "livonia-mi"
    },
    {
        "State": "Michigan",
        "City": "Troy",
        "StateID": "MI",
        "url": "troy-mi"
    },
    {
        "State": "Michigan",
        "City": "Westland",
        "StateID": "MI",
        "url": "westland-mi"
    },
    {
        "State": "Michigan",
        "City": "Farmington Hills",
        "StateID": "MI",
        "url": "farmington-hills-mi"
    },
    {
        "State": "Michigan",
        "City": "Kalamazoo",
        "StateID": "MI",
        "url": "kalamazoo-mi"
    },
    {
        "State": "Michigan",
        "City": "Wyoming",
        "StateID": "MI",
        "url": "wyoming-mi"
    },
    {
        "State": "Michigan",
        "City": "Southfield",
        "StateID": "MI",
        "url": "southfield-mi"
    },
    {
        "State": "Michigan",
        "City": "Rochester Hills",
        "StateID": "MI",
        "url": "rochester-hills-mi"
    },
    {
        "State": "Michigan",
        "City": "Taylor",
        "StateID": "MI",
        "url": "taylor-mi"
    },
    {
        "State": "Michigan",
        "City": "Pontiac",
        "StateID": "MI",
        "url": "pontiac-mi"
    },
    {
        "State": "Michigan",
        "City": "St. Clair Shores",
        "StateID": "MI",
        "url": "st.-clair-shores-mi"
    },
    {
        "State": "Michigan",
        "City": "Royal Oak",
        "StateID": "MI",
        "url": "royal-oak-mi"
    },
    {
        "State": "Michigan",
        "City": "Novi",
        "StateID": "MI",
        "url": "novi-mi"
    },
    {
        "State": "Michigan",
        "City": "Dearborn Heights",
        "StateID": "MI",
        "url": "dearborn-heights-mi"
    },
    {
        "State": "Michigan",
        "City": "Battle Creek",
        "StateID": "MI",
        "url": "battle-creek-mi"
    },
    {
        "State": "Michigan",
        "City": "Saginaw",
        "StateID": "MI",
        "url": "saginaw-mi"
    },
    {
        "State": "Michigan",
        "City": "Kentwood",
        "StateID": "MI",
        "url": "kentwood-mi"
    },
    {
        "State": "Michigan",
        "City": "Roseville",
        "StateID": "MI",
        "url": "roseville-mi"
    },
    {
        "State": "Michigan",
        "City": "Portage",
        "StateID": "MI",
        "url": "portage-mi"
    },
    {
        "State": "Michigan",
        "City": "Midland",
        "StateID": "MI",
        "url": "midland-mi"
    },
    {
        "State": "Michigan",
        "City": "Lincoln Park",
        "StateID": "MI",
        "url": "lincoln-park-mi"
    },
    {
        "State": "Michigan",
        "City": "Muskegon",
        "StateID": "MI",
        "url": "muskegon-mi"
    },
    {
        "State": "Michigan",
        "City": "East Lansing",
        "StateID": "MI",
        "url": "east-lansing-mi"
    },
    {
        "State": "Michigan",
        "City": "Holland",
        "StateID": "MI",
        "url": "holland-mi"
    },
    {
        "State": "Michigan",
        "City": "Bay City",
        "StateID": "MI",
        "url": "bay-city-mi"
    },
    {
        "State": "Michigan",
        "City": "Jackson",
        "StateID": "MI",
        "url": "jackson-mi"
    },
    {
        "State": "Michigan",
        "City": "Burton",
        "StateID": "MI",
        "url": "burton-mi"
    },
    {
        "State": "Michigan",
        "City": "Madison Heights",
        "StateID": "MI",
        "url": "madison-heights-mi"
    },
    {
        "State": "Michigan",
        "City": "Allen Park",
        "StateID": "MI",
        "url": "allen-park-mi"
    },
    {
        "State": "Michigan",
        "City": "Garden City",
        "StateID": "MI",
        "url": "garden-city-mi"
    },
    {
        "State": "Michigan",
        "City": "Inkster",
        "StateID": "MI",
        "url": "inkster-mi"
    },
    {
        "State": "Michigan",
        "City": "Mount Pleasant",
        "StateID": "MI",
        "url": "mount-pleasant-mi"
    },
    {
        "State": "Michigan",
        "City": "Oak Park",
        "StateID": "MI",
        "url": "oak-park-mi"
    },
    {
        "State": "Michigan",
        "City": "Clawson",
        "StateID": "MI",
        "url": "clawson-mi"
    },
    {
        "State": "Michigan",
        "City": "Birmingham",
        "StateID": "MI",
        "url": "birmingham-mi"
    },
    {
        "State": "Michigan",
        "City": "Trenton",
        "StateID": "MI",
        "url": "trenton-mi"
    },
    {
        "State": "Michigan",
        "City": "Monroe",
        "StateID": "MI",
        "url": "monroe-mi"
    },
    {
        "State": "Michigan",
        "City": "Traverse City",
        "StateID": "MI",
        "url": "traverse-city-mi"
    },
    {
        "State": "Michigan",
        "City": "Grosse Pointe Woods",
        "StateID": "MI",
        "url": "grosse-pointe-woods-mi"
    },
    {
        "State": "Michigan",
        "City": "Southgate",
        "StateID": "MI",
        "url": "southgate-mi"
    },
    {
        "State": "Michigan",
        "City": "Harper Woods",
        "StateID": "MI",
        "url": "harper-woods-mi"
    },
    {
        "State": "Michigan",
        "City": "Alma",
        "StateID": "MI",
        "url": "alma-mi"
    },
    {
        "State": "Michigan",
        "City": "Marquette",
        "StateID": "MI",
        "url": "marquette-mi"
    },
    {
        "State": "Michigan",
        "City": "Lapeer",
        "StateID": "MI",
        "url": "lapeer-mi"
    },
    {
        "State": "Michigan",
        "City": "Cadillac",
        "StateID": "MI",
        "url": "cadillac-mi"
    },
    {
        "State": "Michigan",
        "City": "Coldwater",
        "StateID": "MI",
        "url": "coldwater-mi"
    },
    {
        "State": "Michigan",
        "City": "Big Rapids",
        "StateID": "MI",
        "url": "big-rapids-mi"
    },
    {
        "State": "Michigan",
        "City": "Chelsea",
        "StateID": "MI",
        "url": "chelsea-mi"
    },
    {
        "State": "Michigan",
        "City": "Alpena",
        "StateID": "MI",
        "url": "alpena-mi"
    },
    {
        "State": "Michigan",
        "City": "Fenton",
        "StateID": "MI",
        "url": "fenton-mi"
    },
    {
        "State": "Michigan",
        "City": "Greenville",
        "StateID": "MI",
        "url": "greenville-mi"
    },
    {
        "State": "Michigan",
        "City": "Howell",
        "StateID": "MI",
        "url": "howell-mi"
    },
    {
        "State": "Michigan",
        "City": "Ionia",
        "StateID": "MI",
        "url": "ionia-mi"
    },
    {
        "State": "Michigan",
        "City": "Adrian",
        "StateID": "MI",
        "url": "adrian-mi"
    },
    {
        "State": "Michigan",
        "City": "South Lyon",
        "StateID": "MI",
        "url": "south-lyon-mi"
    },
    {
        "State": "Michigan",
        "City": "Riverview",
        "StateID": "MI",
        "url": "riverview-mi"
    },
    {
        "State": "Michigan",
        "City": "Owosso",
        "StateID": "MI",
        "url": "owosso-mi"
    },
    {
        "State": "Michigan",
        "City": "Charlotte",
        "StateID": "MI",
        "url": "charlotte-mi"
    },
    {
        "State": "Michigan",
        "City": "Northville",
        "StateID": "MI",
        "url": "northville-mi"
    },
    {
        "State": "Michigan",
        "City": "Grand Haven",
        "StateID": "MI",
        "url": "grand-haven-mi"
    },
    {
        "State": "Michigan",
        "City": "Ludington",
        "StateID": "MI",
        "url": "ludington-mi"
    },
    {
        "State": "Michigan",
        "City": "Manistee",
        "StateID": "MI",
        "url": "manistee-mi"
    },
    {
        "State": "Michigan",
        "City": "Iron Mountain",
        "StateID": "MI",
        "url": "iron-mountain-mi"
    },
    {
        "State": "Michigan",
        "City": "Niles",
        "StateID": "MI",
        "url": "niles-mi"
    },
    {
        "State": "Michigan",
        "City": "Gladstone",
        "StateID": "MI",
        "url": "gladstone-mi"
    },
    {
        "State": "Michigan",
        "City": "Gaylord",
        "StateID": "MI",
        "url": "gaylord-mi"
    },
    {
        "State": "Michigan",
        "City": "Escanaba",
        "StateID": "MI",
        "url": "escanaba-mi"
    },
    {
        "State": "Michigan",
        "City": "Sturgis",
        "StateID": "MI",
        "url": "sturgis-mi"
    },
    {
        "State": "Michigan",
        "City": "Petoskey",
        "StateID": "MI",
        "url": "petoskey-mi"
    },
    {
        "State": "Michigan",
        "City": "Tecumseh",
        "StateID": "MI",
        "url": "tecumseh-mi"
    },
    {
        "State": "Michigan",
        "City": "Swartz Creek",
        "StateID": "MI",
        "url": "swartz-creek-mi"
    },
    {
        "State": "Michigan",
        "City": "Flat Rock",
        "StateID": "MI",
        "url": "flat-rock-mi"
    },
    {
        "State": "Michigan",
        "City": "Grand Ledge",
        "StateID": "MI",
        "url": "grand-ledge-mi"
    },
    {
        "State": "Michigan",
        "City": "Fraser",
        "StateID": "MI",
        "url": "fraser-mi"
    },
    {
        "State": "Michigan",
        "City": "Wixom",
        "StateID": "MI",
        "url": "wixom-mi"
    },
    {
        "State": "Michigan",
        "City": "Grosse Pointe",
        "StateID": "MI",
        "url": "grosse-pointe-mi"
    },
    {
        "State": "Michigan",
        "City": "Belding",
        "StateID": "MI",
        "url": "belding-mi"
    },
    {
        "State": "Michigan",
        "City": "Caro",
        "StateID": "MI",
        "url": "caro-mi"
    },
    {
        "State": "Michigan",
        "City": "Standish",
        "StateID": "MI",
        "url": "standish-mi"
    },
    {
        "State": "Michigan",
        "City": "Hudsonville",
        "StateID": "MI",
        "url": "hudsonville-mi"
    },
    {
        "State": "Michigan",
        "City": "Davison",
        "StateID": "MI",
        "url": "davison-mi"
    },
    {
        "State": "Michigan",
        "City": "Frankenmuth",
        "StateID": "MI",
        "url": "frankenmuth-mi"
    },
    {
        "State": "Michigan",
        "City": "Rockford",
        "StateID": "MI",
        "url": "rockford-mi"
    },
    {
        "State": "Michigan",
        "City": "East Grand Rapids",
        "StateID": "MI",
        "url": "east-grand-rapids-mi"
    },
    {
        "State": "Michigan",
        "City": "Crystal Falls",
        "StateID": "MI",
        "url": "crystal-falls-mi"
    },
    {
        "State": "Michigan",
        "City": "Mount Clemens",
        "StateID": "MI",
        "url": "mount-clemens-mi"
    },
    {
        "State": "Michigan",
        "City": "New Baltimore",
        "StateID": "MI",
        "url": "new-baltimore-mi"
    },
    {
        "State": "Michigan",
        "City": "Houghton",
        "StateID": "MI",
        "url": "houghton-mi"
    },
    {
        "State": "Michigan",
        "City": "St. Johns",
        "StateID": "MI",
        "url": "st.-johns-mi"
    },
    {
        "State": "Michigan",
        "City": "Saline",
        "StateID": "MI",
        "url": "saline-mi"
    },
    {
        "State": "Michigan",
        "City": "Milan",
        "StateID": "MI",
        "url": "milan-mi"
    },
    {
        "State": "Michigan",
        "City": "Lathrup Village",
        "StateID": "MI",
        "url": "lathrup-village-mi"
    },
    {
        "State": "Michigan",
        "City": "Kingsford",
        "StateID": "MI",
        "url": "kingsford-mi"
    },
    {
        "State": "Michigan",
        "City": "Vassar",
        "StateID": "MI",
        "url": "vassar-mi"
    },
    {
        "State": "Michigan",
        "City": "Hillsdale",
        "StateID": "MI",
        "url": "hillsdale-mi"
    },
    {
        "State": "Michigan",
        "City": "Clare",
        "StateID": "MI",
        "url": "clare-mi"
    },
    {
        "State": "Michigan",
        "City": "Zeeland",
        "StateID": "MI",
        "url": "zeeland-mi"
    },
    {
        "State": "Michigan",
        "City": "Pinckney",
        "StateID": "MI",
        "url": "pinckney-mi"
    },
    {
        "State": "Michigan",
        "City": "Dundee",
        "StateID": "MI",
        "url": "dundee-mi"
    },
    {
        "State": "Michigan",
        "City": "Buchanan",
        "StateID": "MI",
        "url": "buchanan-mi"
    },
    {
        "State": "Michigan",
        "City": "Bridgman",
        "StateID": "MI",
        "url": "bridgman-mi"
    },
    {
        "State": "Michigan",
        "City": "Eaton Rapids",
        "StateID": "MI",
        "url": "eaton-rapids-mi"
    },
    {
        "State": "Michigan",
        "City": "Hastings",
        "StateID": "MI",
        "url": "hastings-mi"
    },
    {
        "State": "Michigan",
        "City": "Beaverton",
        "StateID": "MI",
        "url": "beaverton-mi"
    },
    {
        "State": "Michigan",
        "City": "Harrison",
        "StateID": "MI",
        "url": "harrison-mi"
    },
    {
        "State": "Michigan",
        "City": "Otsego",
        "StateID": "MI",
        "url": "otsego-mi"
    },
    {
        "State": "Michigan",
        "City": "Whitehall",
        "StateID": "MI",
        "url": "whitehall-mi"
    },
    {
        "State": "Michigan",
        "City": "Marine City",
        "StateID": "MI",
        "url": "marine-city-mi"
    },
    {
        "State": "Michigan",
        "City": "Portland",
        "StateID": "MI",
        "url": "portland-mi"
    },
    {
        "State": "Michigan",
        "City": "Clare",
        "StateID": "MI",
        "url": "clare-mi"
    },
    {
        "State": "Michigan",
        "City": "Montrose",
        "StateID": "MI",
        "url": "montrose-mi"
    },
    {
        "State": "Michigan",
        "City": "Jonesville",
        "StateID": "MI",
        "url": "jonesville-mi"
    },
    {
        "State": "Michigan",
        "City": "Bangor",
        "StateID": "MI",
        "url": "bangor-mi"
    },
    {
        "State": "Michigan",
        "City": "Leslie",
        "StateID": "MI",
        "url": "leslie-mi"
    },
    {
        "State": "Michigan",
        "City": "Coloma",
        "StateID": "MI",
        "url": "coloma-mi"
    },
    {
        "State": "Michigan",
        "City": "Norway",
        "StateID": "MI",
        "url": "norway-mi"
    },
    {
        "State": "Michigan",
        "City": "Potterville",
        "StateID": "MI",
        "url": "potterville-mi"
    },
    {
        "State": "Michigan",
        "City": "Evart",
        "StateID": "MI",
        "url": "evart-mi"
    },
    {
        "State": "Michigan",
        "City": "Newaygo",
        "StateID": "MI",
        "url": "newaygo-mi"
    },
    {
        "State": "Michigan",
        "City": "Baraga",
        "StateID": "MI",
        "url": "baraga-mi"
    },
    {
        "State": "Michigan",
        "City": "Bessemer",
        "StateID": "MI",
        "url": "bessemer-mi"
    },
    {
        "State": "Michigan",
        "City": "Cheboygan",
        "StateID": "MI",
        "url": "cheboygan-mi"
    },
    {
        "State": "Michigan",
        "City": "Hartford",
        "StateID": "MI",
        "url": "hartford-mi"
    },
    {
        "State": "Michigan",
        "City": "Lake Odessa",
        "StateID": "MI",
        "url": "lake-odessa-mi"
    },
    {
        "State": "Michigan",
        "City": "Rogers City",
        "StateID": "MI",
        "url": "rogers-city-mi"
    },
    {
        "State": "Michigan",
        "City": "Sandusky",
        "StateID": "MI",
        "url": "sandusky-mi"
    },
    {
        "State": "Michigan",
        "City": "Tawas City",
        "StateID": "MI",
        "url": "tawas-city-mi"
    },
    {
        "State": "Michigan",
        "City": "Clare",
        "StateID": "MI",
        "url": "clare-mi"
    },
    {
        "State": "Michigan",
        "City": "Munising",
        "StateID": "MI",
        "url": "munising-mi"
    },
    {
        "State": "Michigan",
        "City": "Durand",
        "StateID": "MI",
        "url": "durand-mi"
    },
    {
        "State": "Michigan",
        "City": "Vassar",
        "StateID": "MI",
        "url": "vassar-mi"
    },
    {
        "State": "Michigan",
        "City": "Baldwin",
        "StateID": "MI",
        "url": "baldwin-mi"
    },
    {
        "State": "Michigan",
        "City": "Newberry",
        "StateID": "MI",
        "url": "newberry-mi"
    },
    {
        "State": "Michigan",
        "City": "Algonac",
        "StateID": "MI",
        "url": "algonac-mi"
    },
    {
        "State": "Michigan",
        "City": "Harbor Beach",
        "StateID": "MI",
        "url": "harbor-beach-mi"
    },
    {
        "State": "Michigan",
        "City": "Iron River",
        "StateID": "MI",
        "url": "iron-river-mi"
    },
    {
        "State": "Michigan",
        "City": "Kingsley",
        "StateID": "MI",
        "url": "kingsley-mi"
    },
    {
        "State": "Michigan",
        "City": "Beulah",
        "StateID": "MI",
        "url": "beulah-mi"
    },
    {
        "State": "Michigan",
        "City": "Yale",
        "StateID": "MI",
        "url": "yale-mi"
    },
    {
        "State": "Michigan",
        "City": "Parchment",
        "StateID": "MI",
        "url": "parchment-mi"
    },
    {
        "State": "Michigan",
        "City": "Fowlerville",
        "StateID": "MI",
        "url": "fowlerville-mi"
    },
    {
        "State": "Michigan",
        "City": "Centreville",
        "StateID": "MI",
        "url": "centreville-mi"
    },
    {
        "State": "Tennessee",
        "City": "Memphis",
        "StateID": "TN",
        "url": "memphis-tn"
    },
    {
        "State": "Tennessee",
        "City": "Nashville",
        "StateID": "TN",
        "url": "nashville-tn"
    },
    {
        "State": "Tennessee",
        "City": "Knoxville",
        "StateID": "TN",
        "url": "knoxville-tn"
    },
    {
        "State": "Tennessee",
        "City": "Chattanooga",
        "StateID": "TN",
        "url": "chattanooga-tn"
    },
    {
        "State": "Tennessee",
        "City": "Clarksville",
        "StateID": "TN",
        "url": "clarksville-tn"
    },
    {
        "State": "Tennessee",
        "City": "Murfreesboro",
        "StateID": "TN",
        "url": "murfreesboro-tn"
    },
    {
        "State": "Tennessee",
        "City": "Franklin",
        "StateID": "TN",
        "url": "franklin-tn"
    },
    {
        "State": "Tennessee",
        "City": "Jackson",
        "StateID": "TN",
        "url": "jackson-tn"
    },
    {
        "State": "Tennessee",
        "City": "Johnson City",
        "StateID": "TN",
        "url": "johnson-city-tn"
    },
    {
        "State": "Tennessee",
        "City": "Bartlett",
        "StateID": "TN",
        "url": "bartlett-tn"
    },
    {
        "State": "Tennessee",
        "City": "Hendersonville",
        "StateID": "TN",
        "url": "hendersonville-tn"
    },
    {
        "State": "Tennessee",
        "City": "Kingsport",
        "StateID": "TN",
        "url": "kingsport-tn"
    },
    {
        "State": "Tennessee",
        "City": "Collierville",
        "StateID": "TN",
        "url": "collierville-tn"
    },
    {
        "State": "Tennessee",
        "City": "Smyrna",
        "StateID": "TN",
        "url": "smyrna-tn"
    },
    {
        "State": "Tennessee",
        "City": "Cleveland",
        "StateID": "TN",
        "url": "cleveland-tn"
    },
    {
        "State": "Tennessee",
        "City": "Brentwood",
        "StateID": "TN",
        "url": "brentwood-tn"
    },
    {
        "State": "Tennessee",
        "City": "Germantown",
        "StateID": "TN",
        "url": "germantown-tn"
    },
    {
        "State": "Tennessee",
        "City": "Columbia",
        "StateID": "TN",
        "url": "columbia-tn"
    },
    {
        "State": "Tennessee",
        "City": "Cookeville",
        "StateID": "TN",
        "url": "cookeville-tn"
    },
    {
        "State": "Tennessee",
        "City": "Gallatin",
        "StateID": "TN",
        "url": "gallatin-tn"
    },
    {
        "State": "Tennessee",
        "City": "Lebanon",
        "StateID": "TN",
        "url": "lebanon-tn"
    },
    {
        "State": "Tennessee",
        "City": "Morristown",
        "StateID": "TN",
        "url": "morristown-tn"
    },
    {
        "State": "Tennessee",
        "City": "La Vergne",
        "StateID": "TN",
        "url": "la-vergne-tn"
    },
    {
        "State": "Tennessee",
        "City": "Mount Juliet",
        "StateID": "TN",
        "url": "mount-juliet-tn"
    },
    {
        "State": "Tennessee",
        "City": "Spring Hill",
        "StateID": "TN",
        "url": "spring-hill-tn"
    },
    {
        "State": "Tennessee",
        "City": "Maryville",
        "StateID": "TN",
        "url": "maryville-tn"
    },
    {
        "State": "Tennessee",
        "City": "Tullahoma",
        "StateID": "TN",
        "url": "tullahoma-tn"
    },
    {
        "State": "Tennessee",
        "City": "Oak Ridge",
        "StateID": "TN",
        "url": "oak-ridge-tn"
    },
    {
        "State": "Tennessee",
        "City": "Sevierville",
        "StateID": "TN",
        "url": "sevierville-tn"
    },
    {
        "State": "Tennessee",
        "City": "McMinnville",
        "StateID": "TN",
        "url": "mcminnville-tn"
    },
    {
        "State": "Tennessee",
        "City": "Dickson",
        "StateID": "TN",
        "url": "dickson-tn"
    },
    {
        "State": "Tennessee",
        "City": "Shelbyville",
        "StateID": "TN",
        "url": "shelbyville-tn"
    },
    {
        "State": "Tennessee",
        "City": "Elizabethton",
        "StateID": "TN",
        "url": "elizabethton-tn"
    },
    {
        "State": "Tennessee",
        "City": "Athens",
        "StateID": "TN",
        "url": "athens-tn"
    },
    {
        "State": "Tennessee",
        "City": "Soddy-Daisy",
        "StateID": "TN",
        "url": "soddy-daisy-tn"
    },
    {
        "State": "Tennessee",
        "City": "Goodlettsville",
        "StateID": "TN",
        "url": "goodlettsville-tn"
    },
    {
        "State": "Tennessee",
        "City": "Greeneville",
        "StateID": "TN",
        "url": "greeneville-tn"
    },
    {
        "State": "Tennessee",
        "City": "East Ridge",
        "StateID": "TN",
        "url": "east-ridge-tn"
    },
    {
        "State": "Tennessee",
        "City": "Clinton",
        "StateID": "TN",
        "url": "clinton-tn"
    },
    {
        "State": "Tennessee",
        "City": "Paris",
        "StateID": "TN",
        "url": "paris-tn"
    },
    {
        "State": "Tennessee",
        "City": "Farragut",
        "StateID": "TN",
        "url": "farragut-tn"
    },
    {
        "State": "Tennessee",
        "City": "Martin",
        "StateID": "TN",
        "url": "martin-tn"
    },
    {
        "State": "Tennessee",
        "City": "Lawrenceburg",
        "StateID": "TN",
        "url": "lawrenceburg-tn"
    },
    {
        "State": "Tennessee",
        "City": "Signal Mountain",
        "StateID": "TN",
        "url": "signal-mountain-tn"
    },
    {
        "State": "Tennessee",
        "City": "Portland",
        "StateID": "TN",
        "url": "portland-tn"
    },
    {
        "State": "Tennessee",
        "City": "Ripley",
        "StateID": "TN",
        "url": "ripley-tn"
    },
    {
        "State": "Tennessee",
        "City": "Milan",
        "StateID": "TN",
        "url": "milan-tn"
    },
    {
        "State": "Tennessee",
        "City": "White House",
        "StateID": "TN",
        "url": "white-house-tn"
    },
    {
        "State": "Tennessee",
        "City": "Kingston",
        "StateID": "TN",
        "url": "kingston-tn"
    },
    {
        "State": "Tennessee",
        "City": "Dyersburg",
        "StateID": "TN",
        "url": "dyersburg-tn"
    },
    {
        "State": "Tennessee",
        "City": "Manchester",
        "StateID": "TN",
        "url": "manchester-tn"
    },
    {
        "State": "Tennessee",
        "City": "Union City",
        "StateID": "TN",
        "url": "union-city-tn"
    },
    {
        "State": "Tennessee",
        "City": "Erwin",
        "StateID": "TN",
        "url": "erwin-tn"
    },
    {
        "State": "Tennessee",
        "City": "Loudon",
        "StateID": "TN",
        "url": "loudon-tn"
    },
    {
        "State": "Tennessee",
        "City": "Lenoir City",
        "StateID": "TN",
        "url": "lenoir-city-tn"
    },
    {
        "State": "Tennessee",
        "City": "Jefferson City",
        "StateID": "TN",
        "url": "jefferson-city-tn"
    },
    {
        "State": "Tennessee",
        "City": "Sparta",
        "StateID": "TN",
        "url": "sparta-tn"
    },
    {
        "State": "Tennessee",
        "City": "Harriman",
        "StateID": "TN",
        "url": "harriman-tn"
    },
    {
        "State": "Tennessee",
        "City": "Springfield",
        "StateID": "TN",
        "url": "springfield-tn"
    },
    {
        "State": "Tennessee",
        "City": "Brownsville",
        "StateID": "TN",
        "url": "brownsville-tn"
    },
    {
        "State": "Tennessee",
        "City": "Newport",
        "StateID": "TN",
        "url": "newport-tn"
    },
    {
        "State": "Tennessee",
        "City": "Mount Carmel",
        "StateID": "TN",
        "url": "mount-carmel-tn"
    },
    {
        "State": "Tennessee",
        "City": "Sweetwater",
        "StateID": "TN",
        "url": "sweetwater-tn"
    },
    {
        "State": "Tennessee",
        "City": "Rogersville",
        "StateID": "TN",
        "url": "rogersville-tn"
    },
    {
        "State": "Tennessee",
        "City": "Lewisburg",
        "StateID": "TN",
        "url": "lewisburg-tn"
    },
    {
        "State": "Tennessee",
        "City": "Huntingdon",
        "StateID": "TN",
        "url": "huntingdon-tn"
    },
    {
        "State": "Tennessee",
        "City": "Alcoa",
        "StateID": "TN",
        "url": "alcoa-tn"
    },
    {
        "State": "Tennessee",
        "City": "Tazewell",
        "StateID": "TN",
        "url": "tazewell-tn"
    },
    {
        "State": "Tennessee",
        "City": "Lexington",
        "StateID": "TN",
        "url": "lexington-tn"
    },
    {
        "State": "Tennessee",
        "City": "Pigeon Forge",
        "StateID": "TN",
        "url": "pigeon-forge-tn"
    },
    {
        "State": "Tennessee",
        "City": "Smithville",
        "StateID": "TN",
        "url": "smithville-tn"
    },
    {
        "State": "Tennessee",
        "City": "McKenzie",
        "StateID": "TN",
        "url": "mckenzie-tn"
    },
    {
        "State": "Tennessee",
        "City": "Centerville",
        "StateID": "TN",
        "url": "centerville-tn"
    },
    {
        "State": "Tennessee",
        "City": "Dresden",
        "StateID": "TN",
        "url": "dresden-tn"
    },
    {
        "State": "Tennessee",
        "City": "Humboldt",
        "StateID": "TN",
        "url": "humboldt-tn"
    },
    {
        "State": "Tennessee",
        "City": "Selmer",
        "StateID": "TN",
        "url": "selmer-tn"
    },
    {
        "State": "Tennessee",
        "City": "Covington",
        "StateID": "TN",
        "url": "covington-tn"
    },
    {
        "State": "Tennessee",
        "City": "Waynesboro",
        "StateID": "TN",
        "url": "waynesboro-tn"
    },
    {
        "State": "Tennessee",
        "City": "Camden",
        "StateID": "TN",
        "url": "camden-tn"
    },
    {
        "State": "Tennessee",
        "City": "Bolivar",
        "StateID": "TN",
        "url": "bolivar-tn"
    },
    {
        "State": "Tennessee",
        "City": "Livingston",
        "StateID": "TN",
        "url": "livingston-tn"
    },
    {
        "State": "Tennessee",
        "City": "Kingston Springs",
        "StateID": "TN",
        "url": "kingston-springs-tn"
    },
    {
        "State": "Tennessee",
        "City": "Clifton",
        "StateID": "TN",
        "url": "clifton-tn"
    },
    {
        "State": "Tennessee",
        "City": "Crossville",
        "StateID": "TN",
        "url": "crossville-tn"
    },
    {
        "State": "Tennessee",
        "City": "Monterey",
        "StateID": "TN",
        "url": "monterey-tn"
    },
    {
        "State": "Tennessee",
        "City": "Jacksboro",
        "StateID": "TN",
        "url": "jacksboro-tn"
    },
    {
        "State": "Tennessee",
        "City": "White Bluff",
        "StateID": "TN",
        "url": "white-bluff-tn"
    },
    {
        "State": "Tennessee",
        "City": "Loudon",
        "StateID": "TN",
        "url": "loudon-tn"
    },
    {
        "State": "Tennessee",
        "City": "Dayton",
        "StateID": "TN",
        "url": "dayton-tn"
    },
    {
        "State": "Tennessee",
        "City": "Winchester",
        "StateID": "TN",
        "url": "winchester-tn"
    },
    {
        "State": "Tennessee",
        "City": "Carthage",
        "StateID": "TN",
        "url": "carthage-tn"
    },
    {
        "State": "Tennessee",
        "City": "Baxter",
        "StateID": "TN",
        "url": "baxter-tn"
    },
    {
        "State": "Tennessee",
        "City": "Unicoi",
        "StateID": "TN",
        "url": "unicoi-tn"
    },
    {
        "State": "Tennessee",
        "City": "Hohenwald",
        "StateID": "TN",
        "url": "hohenwald-tn"
    },
    {
        "State": "Tennessee",
        "City": "Greeneville",
        "StateID": "TN",
        "url": "greeneville-tn"
    },
    {
        "State": "Tennessee",
        "City": "Jonesborough",
        "StateID": "TN",
        "url": "jonesborough-tn"
    },
    {
        "State": "Tennessee",
        "City": "Lafayette",
        "StateID": "TN",
        "url": "lafayette-tn"
    },
    {
        "State": "Tennessee",
        "City": "Adamsville",
        "StateID": "TN",
        "url": "adamsville-tn"
    },
    {
        "State": "Tennessee",
        "City": "Erin",
        "StateID": "TN",
        "url": "erin-tn"
    },
    {
        "State": "Tennessee",
        "City": "Gainesboro",
        "StateID": "TN",
        "url": "gainesboro-tn"
    },
    {
        "State": "Tennessee",
        "City": "Greenbrier",
        "StateID": "TN",
        "url": "greenbrier-tn"
    },
    {
        "State": "Tennessee",
        "City": "Jasper",
        "StateID": "TN",
        "url": "jasper-tn"
    },
    {
        "State": "Tennessee",
        "City": "Smithville",
        "StateID": "TN",
        "url": "smithville-tn"
    },
    {
        "State": "Tennessee",
        "City": "Norris",
        "StateID": "TN",
        "url": "norris-tn"
    },
    {
        "State": "Tennessee",
        "City": "South Pittsburg",
        "StateID": "TN",
        "url": "south-pittsburg-tn"
    },
    {
        "State": "Tennessee",
        "City": "Waverly",
        "StateID": "TN",
        "url": "waverly-tn"
    },
    {
        "State": "Tennessee",
        "City": "Coopertown",
        "StateID": "TN",
        "url": "coopertown-tn"
    },
    {
        "State": "Tennessee",
        "City": "Oliver Springs",
        "StateID": "TN",
        "url": "oliver-springs-tn"
    },
    {
        "State": "Tennessee",
        "City": "Ashland City",
        "StateID": "TN",
        "url": "ashland-city-tn"
    },
    {
        "State": "Tennessee",
        "City": "New Tazewell",
        "StateID": "TN",
        "url": "new-tazewell-tn"
    },
    {
        "State": "Tennessee",
        "City": "Springfield",
        "StateID": "TN",
        "url": "springfield-tn"
    },
    {
        "State": "Tennessee",
        "City": "Millersville",
        "StateID": "TN",
        "url": "millersville-tn"
    },
    {
        "State": "Tennessee",
        "City": "Savannah",
        "StateID": "TN",
        "url": "savannah-tn"
    },
    {
        "State": "Tennessee",
        "City": "Bells",
        "StateID": "TN",
        "url": "bells-tn"
    },
    {
        "State": "Tennessee",
        "City": "Dunlap",
        "StateID": "TN",
        "url": "dunlap-tn"
    },
    {
        "State": "Tennessee",
        "City": "Oneida",
        "StateID": "TN",
        "url": "oneida-tn"
    },
    {
        "State": "Tennessee",
        "City": "Luttrell",
        "StateID": "TN",
        "url": "luttrell-tn"
    },
    {
        "State": "Tennessee",
        "City": "Whiteville",
        "StateID": "TN",
        "url": "whiteville-tn"
    },
    {
        "State": "Tennessee",
        "City": "Lafayette",
        "StateID": "TN",
        "url": "lafayette-tn"
    },
    {
        "State": "Tennessee",
        "City": "Decatur",
        "StateID": "TN",
        "url": "decatur-tn"
    },
    {
        "State": "Tennessee",
        "City": "Kingston",
        "StateID": "TN",
        "url": "kingston-tn"
    },
    {
        "State": "Tennessee",
        "City": "Tracy City",
        "StateID": "TN",
        "url": "tracy-city-tn"
    },
    {
        "State": "Tennessee",
        "City": "Summertown",
        "StateID": "TN",
        "url": "summertown-tn"
    },
    {
        "State": "Tennessee",
        "City": "Decherd",
        "StateID": "TN",
        "url": "decherd-tn"
    },
    {
        "State": "Tennessee",
        "City": "Rockwood",
        "StateID": "TN",
        "url": "rockwood-tn"
    },
    {
        "State": "Tennessee",
        "City": "Loudon",
        "StateID": "TN",
        "url": "loudon-tn"
    },
    {
        "State": "Tennessee",
        "City": "Camden",
        "StateID": "TN",
        "url": "camden-tn"
    },
    {
        "State": "Tennessee",
        "City": "Oakdale",
        "StateID": "TN",
        "url": "oakdale-tn"
    },
    {
        "State": "Tennessee",
        "City": "Huntingdon",
        "StateID": "TN",
        "url": "huntingdon-tn"
    },
    {
        "State": "Tennessee",
        "City": "Scotts Hill",
        "StateID": "TN",
        "url": "scotts-hill-tn"
    },
    {
        "State": "Tennessee",
        "City": "Lyles",
        "StateID": "TN",
        "url": "lyles-tn"
    },
    {
        "State": "Tennessee",
        "City": "Paris",
        "StateID": "TN",
        "url": "paris-tn"
    },
    {
        "State": "Tennessee",
        "City": "Watertown",
        "StateID": "TN",
        "url": "watertown-tn"
    },
    {
        "State": "Tennessee",
        "City": "Wartburg",
        "StateID": "TN",
        "url": "wartburg-tn"
    },
    {
        "State": "Tennessee",
        "City": "Greenbrier",
        "StateID": "TN",
        "url": "greenbrier-tn"
    },
    {
        "State": "Tennessee",
        "City": "McEwen",
        "StateID": "TN",
        "url": "mcewen-tn"
    },
    {
        "State": "Tennessee",
        "City": "South Fulton",
        "StateID": "TN",
        "url": "south-fulton-tn"
    },
    {
        "State": "Tennessee",
        "City": "Whitwell",
        "StateID": "TN",
        "url": "whitwell-tn"
    },
    {
        "State": "Tennessee",
        "City": "Byrdstown",
        "StateID": "TN",
        "url": "byrdstown-tn"
    },
    {
        "State": "Tennessee",
        "City": "Allardt",
        "StateID": "TN",
        "url": "allardt-tn"
    },
    {
        "State": "Tennessee",
        "City": "Celina",
        "StateID": "TN",
        "url": "celina-tn"
    },
    {
        "State": "Tennessee",
        "City": "Plainview",
        "StateID": "TN",
        "url": "plainview-tn"
    },
    {
        "State": "Tennessee",
        "City": "Obion",
        "StateID": "TN",
        "url": "obion-tn"
    },
    {
        "State": "Tennessee",
        "City": "Monteagle",
        "StateID": "TN",
        "url": "monteagle-tn"
    },
    {
        "State": "Tennessee",
        "City": "Englewood",
        "StateID": "TN",
        "url": "englewood-tn"
    },
    {
        "State": "Tennessee",
        "City": "Mosheim",
        "StateID": "TN",
        "url": "mosheim-tn"
    },
    {
        "State": "Tennessee",
        "City": "Huntingdon",
        "StateID": "TN",
        "url": "huntingdon-tn"
    },
    {
        "State": "Tennessee",
        "City": "Palmer",
        "StateID": "TN",
        "url": "palmer-tn"
    },
    {
        "State": "Tennessee",
        "City": "Ridgetop",
        "StateID": "TN",
        "url": "ridgetop-tn"
    },
    {
        "State": "Tennessee",
        "City": "Friendsville",
        "StateID": "TN",
        "url": "friendsville-tn"
    }
]
export default CitiesData;
